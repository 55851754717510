import React from 'react';
import i18n from 'i18n';
import numeral from 'numeral';
import { IDescriptionItem } from 'common/models/common.model';
import { ENumberFormats } from 'common/consts';
import { ELoanAppStatus, EScores, ownRisks, EPaymentTypes } from 'entities/LoanApp/LoanApp.const';
import { ILoanAppModel, IPaymentItem } from 'entities/LoanApp/LoanApp.models';
import { EUserRoles } from 'entities/User/User.models';
import { loanAppBookmarksTransport } from 'entities/Bookmarks/Bookmarks.transport';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';

export const getScores = (riskValue: EScores, selectedRisk: string) => {
  const foundRisk = ownRisks.find(item => item.value === selectedRisk);

  switch (riskValue) {
    case EScores.Probability:
      return foundRisk?.probOfDefault || '--';
    case EScores.Rate:
      return foundRisk?.avgIntRate || '--';
    case EScores.Target:
      return foundRisk?.targetDefault || '--';
    default:
      return '--';
  }
};

// Temporary helper while BE is not ready
export const getCutUpperCaseId = (id: string) => id.slice(0, 13).toUpperCase();

export const getFormattedSumWithAccountCurrency = (
  value?: string | number,
  format: ENumberFormats = ENumberFormats.Real,
  currency?: string
) => (
  <>
    {currency || <AccountCurrency />}
    {` ${numeral(value).format(format)}`}
  </>
);

export const getMarkupForSumWithAccountCurrency = (
  value?: string | number,
  format: ENumberFormats = ENumberFormats.Real,
  currency?: 'CHF' | 'GBP' | string
): JSX.Element => {
  return (
    <span className="sum-string">
      <span className="sum-string__value">{numeral(value).format(format)} </span>
      <span className="sum-string__currency">{currency || <AccountCurrency />}</span>
    </span>
  );
};

export const getFormattedSum = (value?: string | number, format: ENumberFormats = ENumberFormats.Real) =>
  `${numeral(value).format(format)}`;

export const getLoanDetails = (loanApp: ILoanAppModel, role: string, metricsForInvestor?: boolean) => {
  const {
    amount,
    duration,
    purpose,
    purposeDescription,
    loanAmount,
    interestExpense,
    originationExpense,
    repaymentAmount,
    monthlyPayment,
    loanTotalCost,
    interestRate,
    monthlyInstallments,
    youWillGet,
    yourProfit,
    status,
    investorInterestRate,
    originationFee,
    documentationFee,
    isEarlyRepaid,
    repaymentFee
  } = loanApp;

  const loanPropsLoanAmount = 'loanProps.loanAmount';

  const loanDetailsForBorrower: IDescriptionItem[] = [
    {
      title: i18n.t('loanProps.disbursementAmount'),
      value: amount,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    },
    {
      title: i18n.t(loanPropsLoanAmount),
      value: loanAmount,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    },
    {
      title: i18n.t('loanProps.duration'),
      value: duration,
      formatter: value => `${value} Months`
    },
    {
      title: i18n.t('loanProps.interestRate'),
      value: interestRate,
      formatter: value => `${value} %`
    },
    {
      title: i18n.t('loanProps.purpose'),
      value: purpose === 'Other' ? `${purpose} (${purposeDescription})` : purpose,
      formatter: value => `${value}`
    },
    {
      title: i18n.t('loanProps.interestExpense'),
      value: interestExpense,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    },
    {
      title: i18n.t('loanProps.originationExpense'),
      value: originationExpense,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    },
    {
      title: i18n.t('loanProps.repaymentAmount'),
      value: repaymentAmount,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    },
    {
      title: i18n.t('loanProps.monthlyPayment'),
      value: monthlyPayment,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    },
    {
      title: i18n.t('loanProps.loanTotalCost'),
      value: loanTotalCost,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    }
  ];

  const loanDetailsForInvestor: IDescriptionItem[] = [
    {
      title: i18n.t(loanPropsLoanAmount),
      value: loanAmount,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    },
    {
      title: i18n.t('loanProps.duration'),
      value: duration,
      formatter: value => `${value} Months`
    },
    {
      title: i18n.t('loanProps.interestRate'),
      value: investorInterestRate,
      formatter: value => `${value} %`
    },
    {
      title: i18n.t('loanProps.monthlyInstallments'),
      value: monthlyInstallments,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    },
    {
      title: i18n.t('loanProps.youWillGet'),
      value: youWillGet,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    },
    {
      title: i18n.t('loanProps.yourProfit'),
      value: yourProfit,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    }
  ];

  if (status !== ELoanAppStatus.Verification) {
    loanDetailsForBorrower.push({
      title: i18n.t('loanProps.documentationFee'),
      value: documentationFee,
      formatter: (value, currency) => getFormattedSumWithAccountCurrency(value, undefined, currency)
    });
  }

  if (status === ELoanAppStatus.Verification || status === ELoanAppStatus.AdditionalInfoRequired) {
    return loanDetailsForBorrower.filter(item => item.title !== i18n.t(loanPropsLoanAmount));
  }

  if (role === EUserRoles.Specialist) {
    loanDetailsForBorrower.push(
      {
        title: i18n.t('loanProps.originationFee'),
        value: originationFee,
        formatter: value => `${value} %`
      },
      {
        title: i18n.t('loanProps.earlyRepaid'),
        value: isEarlyRepaid ? 'Yes' : 'No',
        formatter: value => `${value}`
      },
      {
        title: i18n.t('loanProps.feeAmount'),
        value: repaymentFee ? repaymentFee : '0',
        formatter: value => `${value} %`
      }
    );
  }

  switch (role) {
    case EUserRoles.Investor:
      return loanDetailsForInvestor;
    case EUserRoles.Specialist:
      return metricsForInvestor ? loanDetailsForInvestor : loanDetailsForBorrower;
    case EUserRoles.Borrower:
    default:
      return loanDetailsForBorrower;
  }
};

export const addToBookmarks = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, id: string) => {
  e.stopPropagation();
  loanAppBookmarksTransport.addLoanAppBookmarksModel(id);
};

export const deleteFromBookmarks = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, id: string) => {
  e.stopPropagation();
  loanAppBookmarksTransport.deleteLoanAppBookmarksModel(id);
};

export const getLocationData = (path: string) => {
  const locationData = path.split('?tab=');

  return { pathname: locationData[0], search: locationData[1] ? `?tab=${locationData[1]}` : '' };
};

export const getNextPaymentData = (paymentData?: IPaymentItem[]) => {
  return paymentData
    ?.sort((a: IPaymentItem, b: IPaymentItem) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .find(item => item.type === EPaymentTypes.Debt && !item.isPaid);
};
