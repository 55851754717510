import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import { Button, Form, Radio } from 'antd';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { communicationBLink, IBLinkConnectedProps } from 'entities/BLink/BLink.communication';
import { dataRestrictionProviderIdCollection } from 'entities/Onboarding/OnboardingSteps.const';

interface IComponentProps {
  onBack: () => void;
  onManualUploadingStatements: () => void;
}

type AllProps = IBLinkConnectedProps & IComponentProps;

const BankSelectionScreenComponent: React.FC<AllProps> = props => {
  const { onBack, onManualUploadingStatements, bLinkCollection, getBLinkCollection, bLinkModel, initBLinkModel } = props;
  const { data: bLinkCollectionData } = bLinkCollection;
  const { loading: bLinkModelLoading } = bLinkModel;
  const [providerId, setProviderId] = useState<string | null>(null);
  const [hasRestrictions, setHasRestrictions] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: { [key: string]: string }) => {
    const { providerId } = values;

    initBLinkModel(providerId).then(response => {
      setLoading(true);
      window.location.replace(response.authorizationUri);
    });
  };

  useEffect(() => {
    if (!bLinkCollectionData?.length) {
      getBLinkCollection();
    }
  }, [bLinkCollectionData?.length]);

  if (!bLinkCollectionData || bLinkModelLoading || loading) {
    return <LoadingSpinner />;
  }

  return (
    <Form
      className="banking-information"
      onFinish={onFinish}
      onValuesChange={value => {
        setProviderId(value.providerId);
        dataRestrictionProviderIdCollection.includes(value.providerId) ? setHasRestrictions(true) : setHasRestrictions(false);
      }}
    >
      <div>
        <h3 className="onboarding-stepper__block__title">{i18n.t<string>('bankAccountStep.chooseYourBank')}</h3>
        <Form.Item name="providerId" className="banking-information__bank-container">
          <Radio.Group>
            {bLinkCollectionData?.map((item, index) => {
              return (
                <Radio.Button key={index} className="banking-information__bank-item" value={item.providerId}>
                  <img
                    src={`data:${item.companyLogo.small.mimeType};base64,${item.companyLogo.small.image}`}
                    alt={item.companyName}
                  />
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </Form.Item>

        {hasRestrictions && <p>{i18n.t<string>('bankAccountStep.dataRestrictionMessage')}</p>}
      </div>

      <div className="banking-information__footer banking-information__bank-footer">
        <Button className="form-secondary-button" onClick={onBack}>
          {i18n.t<string>('back')}
        </Button>

        {providerId && !hasRestrictions && (
          <Button className="form-submit-button" type="primary" htmlType="submit">
            {i18n.t<string>('bankAccountStep.goToBankWebsite')}
          </Button>
        )}

        {providerId && hasRestrictions && (
          <Button className="form-submit-button" type="primary" onClick={onManualUploadingStatements}>
            {i18n.t<string>('bankAccountStep.uploadCardTitle')}
          </Button>
        )}
      </div>
    </Form>
  );
};

export const BankSelectionScreen = communicationBLink.injector(BankSelectionScreenComponent);
