import i18n from 'i18n';
import { IOnboardingStepperItem } from 'entities/Onboarding/OnboardingSteps.models';

export const monthlyRevenueOptions: string[] = [
  '0-5’000',
  '5’000-10’000',
  '10’000-15’000',
  '15’000-20’000',
  '20’000-30’000',
  '30’000-50’000',
  '50’000-75’000',
  '75’000-100’000',
  '100’000-150’000',
  '150’000-200’000',
  '200’000-300’000',
  '300’000-1’000’0000',
  'Above 1’000’0000'
];

export const businessDescriptionOptions: string[] = [
  'step1DescribeBusinessOptions.accounting',
  'step1DescribeBusinessOptions.airlines',
  'step1DescribeBusinessOptions.alternativeDisputeResolution',
  'step1DescribeBusinessOptions.alternativeMedicine',
  'step1DescribeBusinessOptions.animation',
  'step1DescribeBusinessOptions.apparel',
  'step1DescribeBusinessOptions.architecture',
  'step1DescribeBusinessOptions.arts',
  'step1DescribeBusinessOptions.automotive',
  'step1DescribeBusinessOptions.aviation',
  'step1DescribeBusinessOptions.banking',
  'step1DescribeBusinessOptions.biotechnology',
  'step1DescribeBusinessOptions.broadcastMedia',
  'step1DescribeBusinessOptions.buildingMaterials',
  'step1DescribeBusinessOptions.businessSupplies',
  'step1DescribeBusinessOptions.capitalMarkets',
  'step1DescribeBusinessOptions.chemicals',
  'step1DescribeBusinessOptions.civicOrganization',
  'step1DescribeBusinessOptions.civilEngineering',
  'step1DescribeBusinessOptions.commercialRealEstate',
  'step1DescribeBusinessOptions.computerGames',
  'step1DescribeBusinessOptions.computerHardware',
  'step1DescribeBusinessOptions.computerNetworking',
  'step1DescribeBusinessOptions.computerSoftware',
  'step1DescribeBusinessOptions.computerSecurity',
  'step1DescribeBusinessOptions.construction',
  'step1DescribeBusinessOptions.consumerElectronics',
  'step1DescribeBusinessOptions.consumerGoods',
  'step1DescribeBusinessOptions.consumerServices',
  'step1DescribeBusinessOptions.cosmetics',
  'step1DescribeBusinessOptions.dairy',
  'step1DescribeBusinessOptions.defense',
  'step1DescribeBusinessOptions.design',
  'step1DescribeBusinessOptions.eLearning',
  'step1DescribeBusinessOptions.educationManagement',
  'step1DescribeBusinessOptions.electricalManufacturing',
  'step1DescribeBusinessOptions.entertainmentProduction',
  'step1DescribeBusinessOptions.environmentalServices',
  'step1DescribeBusinessOptions.eventsServices',
  'step1DescribeBusinessOptions.executiveOffice',
  'step1DescribeBusinessOptions.facilitiesServices',
  'step1DescribeBusinessOptions.farming',
  'step1DescribeBusinessOptions.financialServices',
  'step1DescribeBusinessOptions.fineArt',
  'step1DescribeBusinessOptions.fishery',
  'step1DescribeBusinessOptions.foodProduction',
  'step1DescribeBusinessOptions.food',
  'step1DescribeBusinessOptions.fundraising',
  'step1DescribeBusinessOptions.furniture',
  'step1DescribeBusinessOptions.gambling',
  'step1DescribeBusinessOptions.glass',
  'step1DescribeBusinessOptions.governmentAdministration',
  'step1DescribeBusinessOptions.governmentRelations',
  'step1DescribeBusinessOptions.graphicDesign',
  'step1DescribeBusinessOptions.health',
  'step1DescribeBusinessOptions.higherEducation',
  'step1DescribeBusinessOptions.hospital',
  'step1DescribeBusinessOptions.hospitality',
  'step1DescribeBusinessOptions.hr',
  'step1DescribeBusinessOptions.importExport',
  'step1DescribeBusinessOptions.individualFamilyServices',
  'step1DescribeBusinessOptions.industrialAutomation',
  'step1DescribeBusinessOptions.informationServices',
  'step1DescribeBusinessOptions.it',
  'step1DescribeBusinessOptions.insurance',
  'step1DescribeBusinessOptions.internationalAffairs',
  'step1DescribeBusinessOptions.internationalTrade',
  'step1DescribeBusinessOptions.internet',
  'step1DescribeBusinessOptions.investmentBanking',
  'step1DescribeBusinessOptions.investmentManagement',
  'step1DescribeBusinessOptions.judiciary',
  'step1DescribeBusinessOptions.lawEnforcement',
  'step1DescribeBusinessOptions.lawPractice',
  'step1DescribeBusinessOptions.legalServices',
  'step1DescribeBusinessOptions.legislativeOffice',
  'step1DescribeBusinessOptions.leisure',
  'step1DescribeBusinessOptions.library',
  'step1DescribeBusinessOptions.logistics',
  'step1DescribeBusinessOptions.luxuryGoods',
  'step1DescribeBusinessOptions.machinery',
  'step1DescribeBusinessOptions.mManagementConsulting',
  'step1DescribeBusinessOptions.maritime',
  'step1DescribeBusinessOptions.marketResearch',
  'step1DescribeBusinessOptions.marketing',
  'step1DescribeBusinessOptions.mechanicalIndustrialEngineering',
  'step1DescribeBusinessOptions.mediaProduction',
  'step1DescribeBusinessOptions.medicalEquipment',
  'step1DescribeBusinessOptions.medicalPractice',
  'step1DescribeBusinessOptions.mentalHealthCare',
  'step1DescribeBusinessOptions.militaryIndustry',
  'step1DescribeBusinessOptions.mining',
  'step1DescribeBusinessOptions.film',
  'step1DescribeBusinessOptions.museums',
  'step1DescribeBusinessOptions.music',
  'step1DescribeBusinessOptions.nanotechnology',
  'step1DescribeBusinessOptions.newspapers',
  'step1DescribeBusinessOptions.nonProfit',
  'step1DescribeBusinessOptions.oilEnergySolarGreentech',
  'step1DescribeBusinessOptions.onlinePublishing',
  'step1DescribeBusinessOptions.otherIndustry',
  'step1DescribeBusinessOptions.outsourcing',
  'step1DescribeBusinessOptions.packageDelivery',
  'step1DescribeBusinessOptions.packaging',
  'step1DescribeBusinessOptions.paperProducts',
  'step1DescribeBusinessOptions.performing',
  'step1DescribeBusinessOptions.pharmaceuticals',
  'step1DescribeBusinessOptions.philanthropy',
  'step1DescribeBusinessOptions.photography',
  'step1DescribeBusinessOptions.plastics',
  'step1DescribeBusinessOptions.politicalOrganization',
  'step1DescribeBusinessOptions.primaryEducation',
  'step1DescribeBusinessOptions.printing',
  'step1DescribeBusinessOptions.professionalTraining',
  'step1DescribeBusinessOptions.programDevelopment',
  'step1DescribeBusinessOptions.pr',
  'step1DescribeBusinessOptions.publicSafety',
  'step1DescribeBusinessOptions.publishingIndustry',
  'step1DescribeBusinessOptions.railroadManufacture',
  'step1DescribeBusinessOptions.ranching',
  'step1DescribeBusinessOptions.realEstate',
  'step1DescribeBusinessOptions.recreationalFacilities',
  'step1DescribeBusinessOptions.religiousInstitutions',
  'step1DescribeBusinessOptions.renewables',
  'step1DescribeBusinessOptions.researchIndustry',
  'step1DescribeBusinessOptions.restaurants',
  'step1DescribeBusinessOptions.retailIndustry',
  'step1DescribeBusinessOptions.security',
  'step1DescribeBusinessOptions.semiconductors',
  'step1DescribeBusinessOptions.shipbuilding',
  'step1DescribeBusinessOptions.sportingGoods',
  'step1DescribeBusinessOptions.sports',
  'step1DescribeBusinessOptions.staffing',
  'step1DescribeBusinessOptions.supermarkets',
  'step1DescribeBusinessOptions.telecommunications',
  'step1DescribeBusinessOptions.textiles',
  'step1DescribeBusinessOptions.thinkTanks',
  'step1DescribeBusinessOptions.Tobacco',
  'step1DescribeBusinessOptions.translation',
  'step1DescribeBusinessOptions.transportation',
  'step1DescribeBusinessOptions.utilities',
  'step1DescribeBusinessOptions.vc',
  'step1DescribeBusinessOptions.veterinary',
  'step1DescribeBusinessOptions.warehousing',
  'step1DescribeBusinessOptions.wholesale',
  'step1DescribeBusinessOptions.wineSpirits',
  'step1DescribeBusinessOptions.wireless',
  'step1DescribeBusinessOptions.writing'
];

export const companiesStates: string[] = [
  'Zürich',
  'Bern / Berne',
  'Luzern',
  'Uri',
  'Schwyz',
  'Unterwalden',
  'Glarus',
  'Zug',
  'Freiburg / Fribourg',
  'Solothurn',
  'Basel',
  'Schaffhausen',
  'Appenzell',
  'Sankt Gallen',
  'Graubünden',
  'Aargau',
  'Thurgau',
  'Ticino',
  'Vaud',
  'Valais / Wallis',
  'Neuchâtel',
  'Genève',
  'Jura'
];

export const annualProfitsOptions: string[] = [
  '0-5’000',
  '5’000-10’000',
  '10’000-15’000',
  '15’000-20’000',
  '20’000-30’000',
  '30’000-50’000',
  '50’000-75’000',
  '75’000-100’000',
  '100’000-150’000',
  '150’000-200’000',
  '200’000-300’000',
  '300’000 - 1’000’000',
  'Above 1’000’000'
];

export const OTHER_INDUSTRY_LABEL = 'Other';

export const aboutBusinessOptions: string[] = [
  'acceptCreditCards',
  'invoiceCustomers',
  'haveAPriorBankruptcy',
  'useAnAccountingTool',
  'experienceSeasonality',
  'operateAsNonprofit',
  'useAnECommerceTool',
  'operateAsAFranchise'
];

export const loanPurposeOptions: string[] = [
  'Purchase inventory',
  'Purchase equipment',
  'Purchase real estate',
  'Hire employees',
  'Run a marketing campaign',
  'Expansion of business',
  'Purchase a business',
  'Pay suppliers',
  'Refinance debt',
  'Other'
];

export const questionnaireOptions = [
  { label: i18n.t('questionnaireStep.firstCheckbox'), value: 'first' },
  { label: i18n.t('questionnaireStep.secondCheckbox'), value: 'second' },
  { label: i18n.t('questionnaireStep.thirdCheckbox'), value: 'third' }
];

export enum EOnboardingSteps {
  CompanyStep = 'company_step',
  LoanStep = 'loan_step',
  KYCStep = 'kyc_step',
  AccountType = 'account-type_step',
  BankAccountStep = 'ob_step',
  ToS = 'tos_step'
}

export const progressBarForBorrower: IOnboardingStepperItem<EOnboardingSteps>[] = [
  { step: EOnboardingSteps.CompanyStep, title: i18n.t('step1Header.borrower') },
  { step: EOnboardingSteps.LoanStep, title: i18n.t('step2Header.borrower') },
  { step: EOnboardingSteps.BankAccountStep, title: i18n.t('bankAccountStep.header') },
  { step: EOnboardingSteps.KYCStep, title: i18n.t('step3Header'), subTitle: i18n.t('stepperHeaderText') }
];

// TODO: Refactor inbestor
export const progressBarForIndInvestor: IOnboardingStepperItem<string>[] = [
  { step: EOnboardingSteps.AccountType, title: i18n.t('step1Header.investor') },
  { step: EOnboardingSteps.ToS, title: i18n.t('questionnaireStep.header') },
  { step: EOnboardingSteps.KYCStep, title: i18n.t('step3Header'), subTitle: i18n.t('stepperHeaderText') }
];

export const progressBarForCorpInvestor: IOnboardingStepperItem<string>[] = [
  { step: EOnboardingSteps.CompanyStep, title: i18n.t('step2Header.investor') },
  { step: EOnboardingSteps.KYCStep, title: i18n.t('step3Header'), subTitle: i18n.t('stepperHeaderText') }
];

export enum EOpenBankingStatus {
  Success = 'success',
  Failed = 'failed'
}

export const dataRestrictionProviderIdCollection = ['IIDX04835'];
