import { actionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { bLinkTransport } from 'entities/BLink/BLink.transport';
import { IBLinkInitModel, IBLinkModel } from 'entities/BLink/BLink.models';

const namespace = 'bLink';

export interface IBLinkConnectedProps {
  bLinkCollection: StoreBranch<IBLinkModel[]>;
  getBLinkCollection(): void;

  bLinkModel: StoreBranch<IBLinkInitModel>;
  initBLinkModel(providerId: string): Promise<IBLinkInitModel>;
  fetchBLinkModel(externalReference: string): Promise<string>;
}

const collectionApiProvider = [new APIProvider(actionsTypes.get, bLinkTransport.getBLinkCollection)];
const modelApiProvider = [
  new APIProvider(actionsTypes.init, bLinkTransport.initBLinkModel),
  new APIProvider('fetch', bLinkTransport.fetchBLinkModel)
];

const branches = [new Branch('collection', collectionApiProvider), new Branch('model', modelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationBLink = buildCommunication<IBLinkConnectedProps>(strategy);
