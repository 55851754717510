import { useState } from 'react';
import { IKlarnaSession, IKlarnaFetchParams } from 'entities/Klarna/Klarna.models';
import { EOpenBankingStatus } from 'entities/Onboarding/OnboardingSteps.const';

interface IError {
  category: string;
  message?: string;
  reason?: any;
}

export const useKlarna = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<EOpenBankingStatus | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const openKlarnaApp = (
    klarnaSession: IKlarnaSession,
    userId: string,
    fetchKlarnaSession: (params: IKlarnaFetchParams) => void,
    setBankAppOpened: (bankAppOpened: boolean) => void
  ) => {
    setStatus(null);

    // @ts-ignore
    window.XS2A.startFlow(klarnaSession.clientToken, {
      onLoad: () => {
        setLoading(true);
      },
      onReady: () => {
        setLoading(false);
      },
      onError: (error: IError) => {
        setStatus(EOpenBankingStatus.Failed);

        if (error.message) {
          setMessage(error.message);
        }
      },
      onAbort: () => {
        setBankAppOpened(false);
      },
      onFinished: () => {
        setStatus(EOpenBankingStatus.Success);
        fetchKlarnaSession({ sessionId: klarnaSession.sessionId, flowId: klarnaSession.flowId, userId });
      }
    });
  };

  return { openKlarnaApp, loading, status, message };
};
