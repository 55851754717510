import React from 'react';
import { Col, Row } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ILoanRequirements } from 'entities/Onboarding/OnboardingSteps.models';

interface IComponentProps {
  reasons: ILoanRequirements | undefined;
}

export const LoansForbidCreate: React.FC<IComponentProps> = ({ reasons }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={32}>
      <Col>
        <div className="fs-lg ff-bold mb-11">{t('loansForbidCreatePage.description')}</div>

        {!reasons?.isCompanyAgeEligible && (
          <div className="fs-sm mb-3">
            <CloseCircleOutlined className="fs-md mr-4 color-red" />
            {t(`loansForbidCreatePage.error1`)}
          </div>
        )}

        {!reasons?.isIndustryEligible && (
          <div className="fs-sm mb-3">
            <CloseCircleOutlined className="fs-md mr-4 color-red" />
            {t(`loansForbidCreatePage.error2`)}
          </div>
        )}
      </Col>
    </Row>
  );
};
