// https://docs.openbanking.klarna.com/xs2a/xs2a-app.html

// @ts-ignore
// eslint-disable-next-line
window.onXS2AReady = XS2A => {
  // @ts-ignore
  window.XS2A.configure({
    appearanceMode: 'light',
    autoClose: true,
    hideTransitionOnFlowEnd: true,
    openPoliciesInNewWindow: false,
    psd2RedirectMethod: 'sameWindow',
    skipInitialLoader: false,
    theme: 'default',
    unfoldConsentDetails: true
  });
};
