import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { InfoCard } from 'common/components/Card/InfoCard';
import { EBorrowerRoutes } from 'common/models/routes.model';
import Bank from 'app/assets/images/Bank.svg';
import { ReactComponent as LinkIcon } from 'app/assets/icons/Link.svg';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { communicationKyc, IKycConnectedProps } from 'entities/KYCProvider/KYCProvider.communication';

type AllProps = IUsersConnectedProps & IKycConnectedProps;

export const AddBankAccountCardComponent: React.FC<AllProps> = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const rightSlot = (
    <Button
      className="form-secondary-button d-flex d-flex_align_center"
      onClick={() => {
        push(EBorrowerRoutes.BankAccount);
      }}
    >
      {t('borrowerLoans.bankAccount.link')}
      <LinkIcon style={{ marginLeft: '5px' }} />
    </Button>
  );

  return (
    <InfoCard
      className="d-flex_nowrap"
      header={t('borrowerLoans.bankAccount.header')}
      subheader={t('borrowerLoans.bankAccount.description')}
      image={Bank}
      imageAlt="Bank Account doc"
      rightSlot={rightSlot}
    />
  );
};

export const AddBankAccountCard = communicationUser.injector(communicationKyc.injector(AddBankAccountCardComponent));
