import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { useKlarna } from 'common/hooks/OpenBanking.hook';
import { EBorrowerRoutes } from 'common/models/routes.model';
import { BankingInformationStatus } from 'entities/OpenBanking/components/BankingInformationStatus';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { communicationKlarna, IKlarnaConnectedProps } from 'entities/Klarna/Klarna.communication';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { communicationOpenBanking, IOpenBankingConnectedProps } from 'entities/OpenBanking/OpenBanking.communication';
import { BankSelectionScreen } from 'entities/OpenBanking/components/BankSelectionScreen';
import { TransactionUploadMethodScreen } from 'entities/OpenBanking/components/TransactionUploadMethodScreen';
import { ManualUploaderScreen } from 'entities/OpenBanking/components/ManualUploaderScreen';
import { EOnboardingSteps, EOpenBankingStatus } from 'entities/Onboarding/OnboardingSteps.const';
import { communicationBLink, IBLinkConnectedProps } from 'entities/BLink/BLink.communication';
import { EOpenBankingContent } from 'entities/OpenBanking/OpenBanking.const';
import { BankingInformationWaitingScreen } from 'entities/OpenBanking/components/BankingInformationWaitingScreen';

interface IComponentProps {
  onboarding?: boolean;
  skipStep?: () => void;
}

type AllProps = IComponentProps &
  IUsersConnectedProps &
  IOnboardingConnectedProps &
  IKlarnaConnectedProps &
  IBLinkConnectedProps &
  IOpenBankingConnectedProps;

const BankingInformationStepComponent: React.FC<AllProps> = props => {
  const {
    onboarding,
    userCurrent: { data: user },
    onboardingModel: { data: onboardingData },
    onboardingCompanyCollection: { data: companies },
    klarnaSession: { loading: klarnaLoading },
    openBankingStatements: { loading: openBankingLoading },
    skipStep,
    initKlarnaSession,
    fetchKlarnaSession,
    setOnboardingStepsModel,
    getOnboardingCompanyCollection
  } = props;
  const { id: userId } = user || {};
  const openBankingStatus = onboardingData?.ob_step;
  const registrationCountry = companies?.data[0]?.registrationCountry;
  const loading = !user || !companies || klarnaLoading || openBankingLoading;

  const [content, setContent] = useState<string | null>(null);
  const [status, setStatus] = useState<EOpenBankingStatus | null>(null);
  const [bankAppOpened, setBankAppOpened] = useState<boolean>(false);

  const { openKlarnaApp, loading: klarnaAppLoading, status: klarnaStatus, message } = useKlarna();
  const { push, location } = useHistory();
  const locationState = new URLSearchParams(location.state as string);
  const { externalReference } = Object.fromEntries(locationState);

  const onSkip = () => {
    if (onboarding) {
      skipStep?.();
    } else {
      push(EBorrowerRoutes.Loans);
    }
  };

  const onSuccess = () => {
    if (onboarding) {
      setOnboardingStepsModel({ step: EOnboardingSteps.KYCStep });
    } else {
      push(EBorrowerRoutes.Loans);
    }
  };

  const onLinkBankingAccount = () => {
    setContent(EOpenBankingContent.TransactionUploadMethodScreen);

    if (registrationCountry) {
      if (registrationCountry === 'UK') {
        initKlarnaSession().then(response => {
          setBankAppOpened(true);
          userId && openKlarnaApp(response, userId, fetchKlarnaSession, setBankAppOpened);
        });
      } else {
        setContent(EOpenBankingContent.BankSelectionScreen);
      }
    }
  };

  const getContent = (content: string | null) => {
    switch (content) {
      case EOpenBankingContent.TransactionUploadMethodScreen: {
        return (
          <TransactionUploadMethodScreen
            disabled={bankAppOpened}
            onLinkBankingAccount={onLinkBankingAccount}
            onManualUploadingStatements={() => setContent(EOpenBankingContent.ManualUploaderScreen)}
            onSkip={onSkip}
          />
        );
      }
      case EOpenBankingContent.ManualUploaderScreen: {
        return (
          <ManualUploaderScreen
            userId={userId}
            registrationCountry={registrationCountry}
            onSkip={onSkip}
            onSuccess={onSuccess}
            onLinkBankingAccount={onLinkBankingAccount}
          />
        );
      }
      case EOpenBankingContent.BankSelectionScreen: {
        return (
          <BankSelectionScreen
            onBack={() => setContent(EOpenBankingContent.TransactionUploadMethodScreen)}
            onManualUploadingStatements={() => setContent(EOpenBankingContent.ManualUploaderScreen)}
          />
        );
      }
      case EOpenBankingContent.BankingInformationStatus: {
        return (
          <BankingInformationStatus
            status={status}
            message={message}
            onSkip={onSkip}
            onSuccess={onSuccess}
            onLinkBankingAccount={onLinkBankingAccount}
          />
        );
      }
      case EOpenBankingContent.BankingInformationWaitingScreen: {
        return (
          <BankingInformationWaitingScreen
            onboarding={onboarding}
            userId={userId}
            externalReference={externalReference}
            openBankingStatus={openBankingStatus}
            setContent={setContent}
            setStatus={setStatus}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    getOnboardingCompanyCollection();
  }, []);

  useEffect(() => {
    if (klarnaStatus) {
      setStatus(klarnaStatus);
      setContent(EOpenBankingContent.BankingInformationStatus);
    }
  }, [klarnaStatus]);

  useEffect(() => {
    if (!status && openBankingStatus !== 'completed') {
      setContent(EOpenBankingContent.TransactionUploadMethodScreen);
    }

    if (!externalReference && openBankingStatus === 'completed') {
      setStatus(EOpenBankingStatus.Success);
      setContent(EOpenBankingContent.BankingInformationStatus);
    }

    if (externalReference) {
      setContent(EOpenBankingContent.BankingInformationWaitingScreen);
    }
  }, [externalReference, openBankingStatus, status]);

  if (loading || klarnaAppLoading) {
    return <LoadingSpinner />;
  }

  return <div className="banking-information">{getContent(content)}</div>;
};

export const BankingInformationStep = communicationUser.injector(
  communicationOnboarding.injector(
    communicationKlarna.injector(communicationBLink.injector(communicationOpenBanking.injector(BankingInformationStepComponent)))
  )
);
