import React from 'react';
import i18n from 'i18n';
import { Col, message, Row, Spin, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { FILES_SIZE_LIMIT_MAX } from 'common/config';
import { allDocsExts } from 'common/consts';
import { checkFileSize, cutFileName } from 'common/helpers/fileLoader.helper';
import { customUploadRequest } from 'common/helpers/upload.helper';
import UploadIcon from 'app/assets/icons/UploadIcon.svg';
import { IUploadFileModel } from 'entities/KYCProvider/KYCProvider.models';
import { EFileStatus } from 'entities/KYCProvider/KYC.const';

interface IComponentState {
  uploading: boolean;
  files: IUploadFileModel[];
}

interface IComponentProps {
  setLoading?: (value: boolean) => void;
}

interface IAntdFormControl {
  onChange?: (value?: IUploadFileModel[]) => void;
  value?: any;
}

type AllProps = IAntdFormControl & IComponentProps;

class AdditionalDocumentsUploaderComponent extends React.Component<AllProps, IComponentState> {
  state = { uploading: false, files: [] as IUploadFileModel[] };

  render() {
    const { files, uploading } = this.state;

    return (
      <>
        <div className="mb-6 color-grayDark">
          {i18n.t<string>('KYCStepper.fileConstraints', { fileSize: FILES_SIZE_LIMIT_MAX, fileExts: allDocsExts.sign })}
        </div>

        <Row>
          <Col xs={24}>
            <Upload
              type="drag"
              name="file"
              multiple={true}
              customRequest={customUploadRequest}
              accept={allDocsExts.exts}
              showUploadList={false}
              onChange={this.handleChange}
              // @ts-ignore
              beforeUpload={checkFileSize}
              disabled={uploading}
              className="form-upload"
            >
              <Row align="middle" justify="center">
                {uploading ? (
                  <Spin size="small" />
                ) : (
                  <>
                    <img src={UploadIcon} alt="Upload icon" className="mr-4" />
                    <span>{i18n.t<string>('KYCStepper.uploaderTooltip.additionalDocuments')}</span>
                  </>
                )}
              </Row>
            </Upload>
          </Col>
        </Row>

        <Row className="mt-7">
          <Col xs={24}>
            {!!files.length && (
              <>
                <Row align="top" justify="space-between">
                  <span className="loaded-items__title">{i18n.t<string>('KYCStepper.uploadedFiles')}</span>
                  <span onClick={this.onRemoveAll} className="loaded-items__remove-btn">
                    {i18n.t<string>('KYCStepper.removeAll')}
                  </span>
                </Row>
                {files.map(item => (
                  <Row className="loaded-items__item" key={item.id}>
                    <span onClick={() => this.onRemove(item)} className="loaded-items__item_mark">
                      ✕
                    </span>
                    <span className="loaded-items__item_name">{cutFileName(item.name)}</span>
                  </Row>
                ))}
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }

  handleChange = (info: UploadChangeParam<UploadFile<{ id: string }>>) => {
    const { files } = this.state;
    const { onChange, setLoading } = this.props;
    const { file, fileList } = info;
    const { status, response } = file;
    const id = response?.id;

    if (fileList.some(item => item.status === EFileStatus.Uploading)) {
      this.setState({ uploading: true });
      if (setLoading) {
        setLoading(true);
      }
    }

    if (file.status === EFileStatus.Error) {
      this.setState({
        uploading: false
      });
      if (setLoading) {
        setLoading(false);
      }
      message.error(i18n.t<string>('errors:anyUiError'));
    }

    if (status === EFileStatus.Done && id) {
      this.setState({ files: [...files, { name: file.name, id }] });

      if (onChange) {
        onChange([...files, { name: file.name, id }]);
      }
    }

    if (!fileList.some(item => item.status === EFileStatus.Uploading)) {
      this.setState({ uploading: false });
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  onRemove = (file: IUploadFileModel) => {
    const { files } = this.state;
    const { onChange } = this.props;
    const index = files.indexOf(file);
    const newFiles = files.slice();

    newFiles.splice(index, 1);
    this.setState({ files: newFiles });

    if (onChange) {
      onChange(newFiles);
    }
  };

  onRemoveAll = () => {
    const { onChange } = this.props;

    this.setState({ files: [] as IUploadFileModel[] });

    if (onChange) {
      onChange(undefined);
    }
  };
}

export const AdditionalDocumentsUploader = AdditionalDocumentsUploaderComponent;
