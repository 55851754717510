import axios from 'axios';
import { IBLinkInitModel, IBLinkModel } from 'entities/BLink/BLink.models';
import { EOpenBankingStatus } from 'entities/Onboarding/OnboardingSteps.const';

const basePath = '/b-link';

export const bLinkTransport = {
  getBLinkCollection: (): Promise<IBLinkModel[]> => axios.get(`${basePath}/providers`).then(r => r.data),
  initBLinkModel: (providerId: string): Promise<IBLinkInitModel> =>
    axios.post(`${basePath}/providers/${providerId}/init-auth`).then(r => r.data),
  fetchBLinkModel: (externalReference: string): Promise<string> =>
    axios
      .post(`${basePath}/fetch-all`, { externalReference })
      .then(() => EOpenBankingStatus.Success)
      .catch(() => EOpenBankingStatus.Failed)
};
