import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import i18n from 'i18n';
import { FormCard } from 'common/components/Form/FormCard';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { BusinessInfoByBorrower } from 'entities/Onboarding/components/BusinessInfoByBorrower';
import { LoanAppInfoByBorrower } from 'entities/Onboarding/components/LoanAppInfoByBorrower';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { KYCStepper } from 'entities/KYCProvider/components/KYCStepper';
import { getOnboardingStep } from 'entities/Onboarding/OnboardingSteps.helper';
import { EOnboardingSteps } from 'entities/Onboarding/OnboardingSteps.const';
import { BankingInformationStep } from 'entities/OpenBanking/components/BankingInformationStep';

type AllProps = IOnboardingConnectedProps & ILoanAppConnectedProps & IUsersConnectedProps & RouteComponentProps;

class StepperForBorrowerComponent extends React.Component<AllProps> {
  componentDidMount() {
    const { getOnboardingStepsModel } = this.props;

    getOnboardingStepsModel();
  }

  render() {
    const { onboardingModel, onboardingStepsModel } = this.props;
    const step = getOnboardingStep(onboardingStepsModel.data, onboardingModel.data);

    if (!step) {
      return <LoadingSpinner />;
    }

    const renderStep = () => {
      switch (step) {
        case EOnboardingSteps.CompanyStep: {
          return (
            <FormCard title={i18n.t('step1Header.borrower')}>
              <BusinessInfoByBorrower />
            </FormCard>
          );
        }
        case EOnboardingSteps.LoanStep: {
          return (
            <FormCard title={i18n.t<string>('step2Header.borrower')}>
              <LoanAppInfoByBorrower
                onboarding
                skipStep={() => this.skipStep(EOnboardingSteps.LoanStep, EOnboardingSteps.BankAccountStep)}
              />
            </FormCard>
          );
        }
        case EOnboardingSteps.BankAccountStep: {
          return (
            <FormCard title={i18n.t('bankAccountStep.header')}>
              <BankingInformationStep
                onboarding
                skipStep={() => this.skipStep(EOnboardingSteps.BankAccountStep, EOnboardingSteps.KYCStep)}
              />
            </FormCard>
          );
        }
        case EOnboardingSteps.KYCStep: {
          return (
            <FormCard>
              <KYCStepper />
            </FormCard>
          );
        }
        default: {
          return null;
        }
      }
    };

    return renderStep();
  }

  skipStep = (currentStep: EOnboardingSteps, nextStep: EOnboardingSteps) => {
    const { skipOnboardingModel, setOnboardingStepsModel } = this.props;

    setOnboardingStepsModel({ step: nextStep });
    // @ts-ignore
    skipOnboardingModel({ step: currentStep });
  };
}

export const StepperForBorrower = communicationUser.injector(
  communicationOnboarding.injector(communicationLoanApp.injector(withRouter(StepperForBorrowerComponent)))
);
