import React, { useEffect } from 'react';
import i18n from 'i18n';
import { useHistory } from 'react-router';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { EBorrowerRoutes, ECommonRoutes } from 'common/models/routes.model';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { communicationBLink, IBLinkConnectedProps } from 'entities/BLink/BLink.communication';
import { EOpenBankingContent } from 'entities/OpenBanking/OpenBanking.const';
import { EOpenBankingStatus } from 'entities/Onboarding/OnboardingSteps.const';

interface IComponentProps {
  onboarding?: boolean;
  userId?: string;
  externalReference: string;
  openBankingStatus?: string;
  setContent: (content: string) => void;
  setStatus: (status: any) => void;
}

type AllProps = IComponentProps & IOnboardingConnectedProps & IBLinkConnectedProps;

const BankingInformationWaitingScreenComponent: React.FC<AllProps> = props => {
  const { onboarding, userId, externalReference, setContent, setStatus, getOnboardingModel, fetchBLinkModel } = props;
  const { push } = useHistory();
  // @ts-ignore
  let timerId: NodeJS.Timer = null;

  useEffect(() => {
    if (!timerId) {
      fetchBLinkModel(externalReference).then(fetchStatus => {
        if (fetchStatus === EOpenBankingStatus.Failed) {
          setStatus(EOpenBankingStatus.Failed);
          setContent(EOpenBankingContent.BankingInformationStatus);
          onboarding ? push(ECommonRoutes.Onboarding, {}) : push(EBorrowerRoutes.BankAccount, {});
        }

        if (fetchStatus === EOpenBankingStatus.Success) {
          timerId = setInterval(() => {
            userId &&
              getOnboardingModel(userId).then(response => {
                const openBankingStatus = response.ob_step;

                if (openBankingStatus !== 'in_progress') {
                  openBankingStatus === 'completed'
                    ? setStatus(EOpenBankingStatus.Success)
                    : setStatus(EOpenBankingStatus.Failed);

                  clearInterval(timerId);
                  onboarding ? push(ECommonRoutes.Onboarding, {}) : push(EBorrowerRoutes.BankAccount, {});
                  setContent(EOpenBankingContent.BankingInformationStatus);
                }
              });
          }, 5000);
        }
      });
    }
  }, [onboarding, timerId]);

  return (
    <div className="banking-information__waiting-screen">
      <p className="banking-information__waiting-screen_message">{i18n.t<string>('bankAccountStep.waitingScreenMessage')}</p>
      <LoadingSpinner />
    </div>
  );
};

export const BankingInformationWaitingScreen = communicationOnboarding.injector(
  communicationBLink.injector(BankingInformationWaitingScreenComponent)
);
