import React from 'react';
import i18n from 'i18n';
import { message, Row, Spin, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { checkFileSize } from 'common/helpers/fileLoader.helper';
import { allDocsExts } from 'common/consts';
import { FILES_SIZE_LIMIT_MAX } from 'common/config';
import { customUploadRequest } from 'common/helpers/upload.helper';
import UploadIcon from 'app/assets/icons/UploadIcon.svg';
import { LoadedFileField } from 'entities/KYCProvider/components/LoadedFileField';
import { EFileStatus } from 'entities/KYCProvider/KYC.const';
import { IUploadFileModel } from 'entities/KYCProvider/KYCProvider.models';

interface IComponentProps {
  fieldId: string;
  acceptedExts?: string;
  placeholder?: string;
  disabled: boolean;
}

interface IAntdFormControl {
  onChange?: (value?: IUploadFileModel) => void;
  value?: IUploadFileModel;
}

type AllProps = IAntdFormControl & IComponentProps;

interface IComponentState {
  uploading: boolean;
  id?: string;
  name?: string;
}

class SingleFileUploader extends React.Component<AllProps, IComponentState> {
  static getDerivedStateFromProps(nextProps: AllProps) {
    if (nextProps.value) {
      return {
        id: nextProps.value.id,
        name: nextProps.value.name
      };
    }

    return null;
  }
  constructor(props: AllProps) {
    super(props);
    this.state = { uploading: false, id: props.value?.id, name: props.value?.id };
  }

  render() {
    const { fieldId, acceptedExts, placeholder = i18n.t('KYCStepper.uploadFile'), disabled } = this.props;
    const { uploading, id, name } = this.state;

    return id && name ? (
      <LoadedFileField name={name} id={id} removeFile={this.onRemove} disabled={disabled} />
    ) : (
      <Upload
        id={fieldId}
        type="drag"
        name="file"
        customRequest={customUploadRequest}
        accept={acceptedExts || allDocsExts.exts}
        showUploadList={false}
        onChange={this.handleChange}
        // @ts-ignore
        beforeUpload={checkFileSize}
        disabled={uploading || disabled}
        className="form-upload"
      >
        <Row align="middle" justify="center">
          {uploading ? (
            <Spin size="small" />
          ) : (
            <>
              <img src={UploadIcon} alt="Upload icon" className="mr-4" />
              <span>{placeholder}</span>
            </>
          )}
        </Row>
      </Upload>
    );
  }

  handleChange = (info: UploadChangeParam<UploadFile<{ id: string }>>) => {
    const { onChange } = this.props;
    const { file } = info;
    const { status, response, name } = file;
    const id = response?.id;

    this.setState({
      uploading: true
    });
    if (!checkFileSize(file)) {
      this.setState({
        uploading: false
      });
    }

    if (status === EFileStatus.Error) {
      this.setState({
        uploading: false
      });
      message.error(i18n.t<string>('errors:anyUiError'));
    }

    if (status === EFileStatus.Done && id) {
      this.setState({ uploading: false });
      if (onChange) {
        onChange({ id, name });
      }
    }
  };

  onRemove = () => {
    const { onChange } = this.props;
    this.setState({ id: undefined, name: undefined });
    if (onChange) {
      onChange();
    }
  };

  validateSize = (rule: any, value: any, callback: any) => {
    if (!checkFileSize(value.file)) {
      return callback(i18n.t('errors:sizeMustBeLessThan', { size: FILES_SIZE_LIMIT_MAX }));
    }
    return callback();
  };
}

export default SingleFileUploader;
