import * as React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import axios from 'axios';
import { configureAxiosJWTInterseptors } from '@axmit/axios-patch-jwt';
import { createBrowserHistory } from 'history';
import { IAsyncStorage, WebStorageDecorator } from 'universal-storage';
import 'i18n';
import 'klarna';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import { EErrorStatus } from 'common/models/errors.model';
import { translateValidationError } from 'common/helpers/errors.helper';
import createStore from 'app/store/createStore';
import App from 'app/App';
import 'app/assets/sass/Styles.scss';

// Sentry initialization

Sentry.init({
  dsn: 'https://fd297081350543908fb23b7865a44402@o381818.ingest.sentry.io/5210142',
  environment: process.env.NODE_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'Non-Error promise rejection captured']
});

// Axios initialization

const storage: IAsyncStorage = new WebStorageDecorator(localStorage);
configureAxiosJWTInterseptors({
  storage,
  // @ts-ignore
  axios,
  refreshTokenEndpoint: '/auth'
});

axios.defaults.baseURL = `/api`;
axios.interceptors.request.use(config => config);
axios.interceptors.response.use(
  response => response,
  error => {
    const { status, statusText, data } = error.response;

    // TODO Refactor logic for errors translation
    if (status === EErrorStatus.Validation && Array.isArray(data.errors)) {
      translateValidationError(data.errors);
    }

    return Promise.reject({ status, statusText, data });
  }
);

// Render Setup

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const initialState = (window as any).___INITIAL_STATE__;
const history = createBrowserHistory();
const store = createStore(initialState, history);
const root = createRoot(MOUNT_NODE);

root.render(
  <Provider store={store}>
    {/* @ts-ignore */}
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);
