import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import { Attachment } from 'common/components/Attachment';
import { Description } from 'common/components/Description/Description';
import { IFileModel } from 'entities/LoanApp/LoanApp.models';
import { IUserFileModel } from 'entities/User/User.models';
import { docNames } from 'entities/KYCProvider/KYC.const';
import { communicationOpenBanking, IOpenBankingConnectedProps } from 'entities/OpenBanking/OpenBanking.communication';

interface IComponentProps {
  files: IUserFileModel[];
  userId?: string;
}

type AllProps = IOpenBankingConnectedProps & IComponentProps;

export const AttachedDocsComponent: React.FC<AllProps> = props => {
  const { files, userId, getOpenBankingStatements } = props;
  const [bankStatements, setBankStatements] = useState<JSX.Element>();
  const attachmentsMap = new Map<string, IFileModel[]>();

  files.forEach((item: any) => {
    const { step, file } = item;
    const docData = attachmentsMap.get(step);

    if (file) {
      if (docData) {
        docData.push(file);
        attachmentsMap.set(step, docData);
      } else {
        attachmentsMap.set(step, [file]);
      }
    }
  });
  const attachments: JSX.Element[] = [];

  attachmentsMap.forEach((value: IFileModel[], key: string) =>
    attachments.push(<Attachment key={key} title={docNames[key] || key} files={value} />)
  );

  useEffect(() => {
    if (userId) {
      getOpenBankingStatements({ userId }).then(response => {
        !!response.data.length &&
          setBankStatements(<Attachment key={userId} title={i18n.t('bankStatements')} files={response.data} />);
      });
    }
  }, [userId]);

  return (
    <Description classNameBody="pt-7" header={i18n.t('attachedDocuments')}>
      {attachments}
      {bankStatements}
    </Description>
  );
};

export const AttachedDocs = communicationOpenBanking.injector(AttachedDocsComponent);
