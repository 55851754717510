import {
  actionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  IFullState,
  StoreBranch
} from '@axmit/redux-communications';
import { RequestLoadingHelper } from 'common/helpers/RequestLoading.helper';
import { showPostFailErrors } from 'common/helpers/errors.helper';
import {
  ICompanyCollection,
  ICompanyCreateParams,
  IIndustryCollection,
  IIndustryCollectionParams,
  IOnboarding,
  IOnboardingStepsModel,
  ISkipOnboardingParams,
  IZefixModel
} from 'entities/Onboarding/OnboardingSteps.models';
import { onboardingTransport } from 'entities/Onboarding/OnboardingSteps.transport';
import { ILoanAppCreateModel } from 'entities/LoanApp/LoanApp.models';
import { getAuthUser } from 'entities/Auth/Auth.communication';

const namespace = 'onboarding';

export interface IOnboardingConnectedProps {
  onboardingCompanyCollection: StoreBranch<ICompanyCollection>;
  getOnboardingCompanyCollection(): Promise<ICompanyCollection>;

  onboardingZefixModel: StoreBranch<IZefixModel>;
  getByUserOnboardingZefixModel(userId: string): void;
  clearOnboardingZefixModel(): void;

  onboardingIndustryCollection: StoreBranch<IIndustryCollection>;
  getOnboardingIndustryCollection(params: IIndustryCollectionParams): Promise<IIndustryCollection>;

  onboardingModel: StoreBranch<IOnboarding>;
  getOnboardingModel(userId: string): Promise<IOnboarding>;
  skipOnboardingModel(params: ISkipOnboardingParams): void;
  addCompanyOnboardingModel(params: ICompanyCreateParams): void;
  addLoanOnboardingModel(params: ILoanAppCreateModel): void;

  onboardingStepsModel: StoreBranch<IOnboardingStepsModel>;
  getOnboardingStepsModel(): void;
  setOnboardingStepsModel(params: IOnboardingStepsModel): void;
  clearOnboardingStepsModel(): void;
}

const postSuccess = (response: any, payload: any) => {
  if (payload.onSuccess) {
    payload.onSuccess();
  }
};

const modelApiProvider = [
  new APIProvider(actionsTypes.get, onboardingTransport.getOnboarding),
  new APIProvider('addCompany', onboardingTransport.addOnboardingCompany, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    postSuccessHook: function*(response: any, payload: any, branchState: any, fullState: IFullState) {
      // @ts-ignore
      const userId = fullState?.user?.current?.data.id;

      if (userId) {
        yield getAuthUser(userId);
        payload.onSuccess();
      }
    },
    postFailHook: showPostFailErrors
  }),
  new APIProvider('addLoan', onboardingTransport.addOnboardingLoan, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    postSuccessHook: postSuccess
  }),
  new APIProvider('skip', onboardingTransport.skipOnboarding, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    // tslint:disable-next-line:typedef
    postSuccessHook: postSuccess,
    postFailHook: (response: any, payload: any) => {
      if (payload.onFail) {
        payload.onFail();
      }
    }
  })
];

const companyCollectionApiProvider = [new APIProvider(actionsTypes.get, onboardingTransport.getCompanyCollection)];
const zefixModelApiProvider = [new APIProvider('getByUser', onboardingTransport.getByUserZefixModel)];

const industryCollectionApiProvider = [new APIProvider(actionsTypes.get, onboardingTransport.getIndustryCollection)];

const onboardingSteps = 'onboarding-steps';

const stepsModelApiProvider = [
  new APIProvider(
    actionsTypes.get,
    (): Promise<IOnboardingStepsModel> => {
      const json = sessionStorage.getItem(onboardingSteps);
      return Promise.resolve(json ? JSON.parse(json) : { step: null, kycStep: null });
    }
  ),
  new APIProvider(
    'set',
    (params: IOnboardingStepsModel): Promise<IOnboardingStepsModel> => {
      sessionStorage.setItem(onboardingSteps, JSON.stringify(params));
      return Promise.resolve(params);
    }
  ),
  new APIProvider(
    actionsTypes.clear,
    (): Promise<void> => {
      sessionStorage.removeItem(onboardingSteps);
      return Promise.resolve();
    }
  )
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('companyCollection', companyCollectionApiProvider),
  new Branch('zefixModel', zefixModelApiProvider),
  new Branch('industryCollection', industryCollectionApiProvider),
  new Branch('stepsModel', stepsModelApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationOnboarding = buildCommunication<IOnboardingConnectedProps>(strategy);
