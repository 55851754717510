import React from 'react';
import i18n from 'i18n';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Description } from 'common/components/Description/Description';
import { DescriptionItem } from 'common/components/Description/DescriptionItem';
import { Popup } from 'common/components/Popup';
import { getLoanDetails } from 'entities/LoanApp/LoanApp.helpers';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { LoanAppInfoByBorrower } from 'entities/Onboarding/components/LoanAppInfoByBorrower';
import { EUserRoles, IUserModel } from 'entities/User/User.models';
import { ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';

interface IComponentState {
  edit: boolean;
}

interface IComponentProps {
  currency?: string;
  editable?: boolean;
  header?: string;
  metricsForInvestor?: boolean;
}

type AllProps = IUsersConnectedProps & ILoanAppConnectedProps & IComponentProps;

class LoanDetailsComponent extends React.Component<AllProps, IComponentState> {
  state = { edit: false };

  componentDidMount() {
    const { loanAppModel, getUserModel } = this.props;
    const { data: loanApp } = loanAppModel;

    // @ts-ignore
    if (loanApp?.status === ELoanAppStatus.Active && loanApp.lenderId) {
      // @ts-ignore
      getUserModel({ id: loanApp.lenderId });
    }
  }

  componentWillUnmount() {
    const { clearUserModel } = this.props;

    clearUserModel();
  }

  render() {
    const { edit } = this.state;
    const { loanAppModel, editable, userCurrent, header, metricsForInvestor, userModel } = this.props;
    const { data: loanApp } = loanAppModel;
    const { data: lenderData } = userModel;
    const currency = loanApp?.currency;
    const role = userCurrent.data?.role;

    if (!loanApp) {
      return null;
    }

    const loanDetailsItems = role && getLoanDetails(loanApp, role, metricsForInvestor);

    return (
      <>
        <Description
          header={header ? header : i18n.t('loanDetails')}
          footer={
            editable ? (
              <Button className="submit-button" type="primary" onClick={this.toggleEdit}>
                {i18n.t<string>('editLoanInfoButton')}
              </Button>
            ) : (
              undefined
            )
          }
        >
          {loanDetailsItems &&
            loanDetailsItems.map(
              ({ title, value, formatter }, index) =>
                !!value && <DescriptionItem key={index} title={title} endValue={formatter(value, currency)} />
            )}

          {role === EUserRoles.Specialist && lenderData && (
            <DescriptionItem
              title={i18n.t('loanProps.lender')}
              endValue={<Link to={`/users/${lenderData.id}`}>{this.getLenderProfileLinkName(lenderData)}</Link>}
            />
          )}
        </Description>

        {editable && (
          <Popup
            title={i18n.t<string>('editLoanInfoButton')}
            visible={edit}
            onCancel={this.toggleEdit}
            className="loan-edit-popup"
          >
            <LoanAppInfoByBorrower edit onSubmit={this.toggleEdit} />
          </Popup>
        )}
      </>
    );
  }

  toggleEdit = () => {
    this.setState(prevState => ({
      edit: !prevState.edit
    }));
  };

  getLenderProfileLinkName = (lender: IUserModel): string => {
    const { profile, companies } = lender;
    return `${profile?.firstName} ${profile?.lastName} ${companies ? ` / ${companies[0].name}` : ''}`;
  };
}

export const LoanDetails = communicationUser.injector(communicationLoanApp.injector(LoanDetailsComponent));
