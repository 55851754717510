export enum ECommonRoutes {
  Main = '/',
  SignUp = '/sign-up',
  LogIn = '/login',
  AuthError = '/auth-error',
  RestorePass = '/restore-password',
  CreatePass = '/new-password/code',
  EmailConfirm = '/email-confirmation',
  Profile = '/profile',
  UserConfirm = '/user/confirm',
  Onboarding = '/onboarding',
  Transactions = '/transactions',
  Wallet = '/wallet',
  TermsAndConditions = '/wp-content/uploads/2022/10/MidFunder_ANB_Final_EN.pdf',
  PrivacyPolicy = '/wp-content/uploads/2022/09/PrivacyPolicy_Final.pdf',
  BlinkRedirect = '/blink/redirect'
}

export enum EBorrowerRoutes {
  Loans = '/loans',
  Loan = '/loans/:id',
  LoanAddDocs = '/loans/:id/add-docs',
  CreateLoan = '/loans/new',
  Performance = '/performance',
  KYC = '/kyc',
  Profile = '/profile',
  BankAccount = '/bank-account'
}

export enum EInvestorRoutes {
  Dashboard = '/dashboard',
  Marketplace = '/marketplace',
  Investments = '/investments',
  InvestPage = '/invest-page',
  InvestPageItem = '/invest-page/:id',
  KYC = '/kyc'
}

export enum ESpecialistRoutes {
  Loans = '/loans',
  LoanItem = '/loans/:id',
  UserItem = '/users/:id',
  Users = '/users/',
  Investors = '/investors',
  Clients = '/clients'
}

export enum EAdminRoutes {
  Managers = '/managers',
  AddManager = '/add-new-manager'
}
