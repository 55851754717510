import {
  actionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put, select } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';
import i18n from 'i18n';
import { getCurrentUserId, requestUser } from 'common/helpers/requestUser.helper';
import { ICollectionRequestParams, IModelRequestParams, IRequestSuccess } from 'common/models/request.model';
import { EErrorStatus } from 'common/models/errors.model';
import { showPostFailErrors } from 'common/helpers/errors.helper';
import { IApplicationState } from 'app/store/reducers';
import {
  ISyncDocuSign,
  IGetLoanAppCollectionParams,
  ILoanAppCollection,
  ILoanAppConfirmInvestModel,
  ILoanAppCreateModel,
  ILoanAppInvestRequestModel,
  ILoanAppInvestResponseModel,
  ILoanAppModel,
  ILoanAppSign,
  ILoanAppUpdateModel,
  ILoanStatModel,
  IRepaymentDetailsModel,
  ILoanApplicationDeclineParams
} from 'entities/LoanApp/LoanApp.models';
import { loanAppTransport } from 'entities/LoanApp/LoanApp.transport';
import { EUserRoles, IUserCollection } from 'entities/User/User.models';
import { ELoanAppInvestStatus } from 'entities/LoanApp/LoanApp.const';
import { IPaymentScheduleCollection } from 'entities/PaymentSchedules/PaymentSchedules.models';
import { paymentSchedulesTransport } from 'entities/PaymentSchedules/PaymentSchedules.transport';

const namespace = 'loanApp';

export interface ILoanAppStoreProps {
  model: StoreBranch<ILoanAppModel>;
  collection: StoreBranch<ILoanAppCollection>;
}

export interface ILoanAppConnectedProps {
  loanAppModel: StoreBranch<ILoanAppModel, ILoanAppCreateModel, any>;
  loanAppCollection: StoreBranch<ILoanAppCollection>;
  loanAppSign: StoreBranch<ILoanAppSign>;
  loanAppInvest: StoreBranch<ILoanAppInvestResponseModel>;
  loanAppInvestConfirm: StoreBranch<ILoanAppInvestResponseModel>;
  loanAppSigners: StoreBranch<IUserCollection>;
  loanAppInvestors: StoreBranch<IUserCollection>;
  loanAppPaymentSchedule: StoreBranch<IPaymentScheduleCollection>;
  loanAppStat: StoreBranch<ILoanStatModel>;
  loanAppDocuSign: StoreBranch<ISyncDocuSign>;
  loanAppRepaymentDetails: StoreBranch<IRepaymentDetailsModel>;
  addLoanAppModel(params: ILoanAppCreateModel): Promise<void>;
  updateLoanAppModel(params: ILoanAppUpdateModel): void;
  defaultLoanAppModel(loanId: string): void;
  declineLoanAppModel(params: ILoanApplicationDeclineParams): void;
  clearLoanAppModel(): void;
  addLoanAppSign(id: string): void;
  getLoanAppSign(id: string): void;
  getLoanAppModel(params: IModelRequestParams): void;
  getLoanAppCollection(params: IGetLoanAppCollectionParams): Promise<ILoanAppCollection>;
  clearLoanAppCollection(): void;
  addLoanAppInvest(params: ILoanAppInvestRequestModel): void;
  addLoanAppInvestConfirm(params: ILoanAppConfirmInvestModel): void;
  getLoanAppSigners(loanId: string): void;
  getLoanAppInvestors(loanId: string): void;
  getLoanAppPaymentSchedule(id: string): void;
  getLoanAppStat(loanId: string): void;
  syncLoanAppDocuSign(
    params: IRequestSuccess & {
      loanId: string;
    }
  ): void;
  getLoanAppRepaymentDetails(loanId: string): void;
  addLoanAppRepaymentDetails(loanId: string): void;
}

const signAndInvestFailHook = (response: any) => {
  const status = response && response.status;

  if (status === EErrorStatus.Forbidden) {
    message.error(i18n.t<string>('errors:signAndInvestError'));
    return;
  }

  showPostFailErrors(response);
};

const modelApiProvider = [
  new APIProvider(actionsTypes.add, loanAppTransport.addLoanApp, {
    postSuccessHook: function*() {
      yield requestUser();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      message.success(i18n.t<string>('step2SuccessLoanCreating'));
    }
  }),
  new APIProvider(actionsTypes.get, loanAppTransport.getLoanAppModel, {
    postSuccessHook: function*(response: ILoanAppModel): any {
      const role = yield getCurrentUserRole();

      if (role === EUserRoles.Investor) {
        yield put({ type: getStartType(namespace, 'signers', actionsTypes.get), payload: response.id });
        yield put({ type: getStartType(namespace, 'investors', actionsTypes.get), payload: response.id });
      }
    }
  }),
  new APIProvider(actionsTypes.update, loanAppTransport.updateLoanApp, {
    postSuccessHook: function*(response: ILoanAppModel): any {
      const role = yield getCurrentUserRole();

      if (role === EUserRoles.Specialist) {
        yield put({
          type: getStartType('loanAppAudit', 'collection', actionsTypes.get),
          payload: {
            id: response.id,
            limit: 50
          }
        });
      }
    }
  }),
  new APIProvider('default', loanAppTransport.defaultLoanAppModel, {
    postSuccessHook: function*(response, payload) {
      yield put({ type: getStartType(namespace, 'model', actionsTypes.get), payload: { id: payload } });
    }
  }),
  new APIProvider('decline', loanAppTransport.declineLoanAppModel, {
    postSuccessHook: function*(response, payload) {
      yield put({ type: getStartType(namespace, 'model', actionsTypes.get), payload: { id: payload.id } });
    }
  })
];

const collectionApiProvider = [
  new APIProvider(actionsTypes.get, loanAppTransport.getLoanAppCollection, {
    preRequestDataMapper: function(
      response: ILoanAppCollection | null,
      payload: ICollectionRequestParams,
      branchState: StoreBranch<ILoanAppCollection, ICollectionRequestParams>
    ) {
      return branchState.data;
    },
    postSuccessHook: function*(response: ILoanAppCollection, payload: ICollectionRequestParams): any {
      const role = yield getCurrentUserRole();

      if (role === EUserRoles.Borrower && response.data.length > 0) {
        const currLoanId = response.data[0].id;
        yield put({ type: getStartType(namespace, 'paymentSchedule', actionsTypes.get), payload: currLoanId });

        const payloadForModel: IModelRequestParams = { id: currLoanId };

        if (payload.event) {
          payloadForModel.event = payload.event;
        }

        yield put({
          type: getStartType(namespace, 'model', actionsTypes.get),
          payload: payloadForModel
        });
      }
    }
  })
];

const signApiProvider = [
  new APIProvider(actionsTypes.add, loanAppTransport.addLoanAppSign, {
    postSuccessHook: response => {
      window.location.assign(response.url);
    },
    postFailHook: response => signAndInvestFailHook(response)
  }),
  new APIProvider(actionsTypes.get, loanAppTransport.getLoanAppSign, {
    postSuccessHook: response => {
      window.location.assign(response.url);
    },
    postFailHook: response => signAndInvestFailHook(response)
  })
];

const investApiProvider = [
  new APIProvider(actionsTypes.add, loanAppTransport.investLoanApp, {
    postSuccessHook: function*(response: ILoanAppInvestResponseModel, payload: ILoanAppInvestRequestModel) {
      const { url, status } = response;

      yield updateBalance();

      if (status === ELoanAppInvestStatus.Invested) {
        yield put({ type: getStartType(namespace, 'model', actionsTypes.get), payload: { id: payload.id } });
      }

      if (url && status === ELoanAppInvestStatus.WaitingForConfirm) {
        window.location.assign(url);
      }
    },
    postFailHook: response => signAndInvestFailHook(response)
  })
];

const investConfirmApiProvider = [
  new APIProvider(actionsTypes.add, loanAppTransport.confirmInvestLoanApp, {
    postSuccessHook: function*(response: ILoanAppInvestResponseModel, payload: ILoanAppConfirmInvestModel) {
      yield updateBalance();
      yield put({ type: getStartType(namespace, 'model', actionsTypes.get), payload: { id: payload.id } });
    },
    postFailHook: function*(response: any) {
      const status = response?.status;

      if (status === EErrorStatus.NotFound) {
        yield put(push({ search: '' }));
      }
    }
  })
];

const signersApiProvider = [new APIProvider(actionsTypes.get, loanAppTransport.getLoanAppSigners)];

const investorsApiProvider = [
  new APIProvider(actionsTypes.get, loanAppTransport.getLoanAppInvestors, {
    postSuccessHook: function*(response: IUserCollection, payload: string): any {
      const userId = yield getCurrentUserId();
      const investedByCurrentUser = response.data.find(item => item.id === userId);

      if (investedByCurrentUser) {
        yield put({ type: getStartType(namespace, 'paymentSchedule', actionsTypes.get), payload });
      }
    }
  })
];

const paymentScheduleApiProvider = [new APIProvider(actionsTypes.get, paymentSchedulesTransport.getPaymentSchedule)];

const statApiProvider = [new APIProvider(actionsTypes.get, loanAppTransport.getStat)];

const docuSignApiProvider = [
  new APIProvider('sync', loanAppTransport.syncDocuSign, {
    postSuccessHook: function*(response: ISyncDocuSign, payload): any {
      if (!response.isSigned) {
        return;
      }

      if (payload.onSuccess) {
        payload.onSuccess();
        return;
      }

      yield put({ type: getStartType(namespace, 'collection', actionsTypes.get), payload: {} });
    }
  })
];

const repaymentDetailsApiProvider = [
  new APIProvider(actionsTypes.get, loanAppTransport.getLoanAppRepaymentDetails),
  new APIProvider(actionsTypes.add, loanAppTransport.addLoanAppRepaymentDetails, {
    postSuccessHook: function*(response, payload) {
      yield put({ type: getStartType(namespace, 'model', actionsTypes.get), payload: { id: payload } });
      yield put({ type: getStartType(namespace, 'paymentSchedule', actionsTypes.get), payload });
      yield updateBalance();
    }
  })
];

function* getCurrentUserRole(): any {
  return yield select((state: IApplicationState) => state.user.current.data?.role);
}

export function* clearLoanAppModel() {
  yield put({ type: getStartType(namespace, 'model', 'clear') });
}

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('collection', collectionApiProvider),
  new Branch('sign', signApiProvider),
  new Branch('invest', investApiProvider),
  new Branch('signers', signersApiProvider),
  new Branch('investors', investorsApiProvider),
  new Branch('investConfirm', investConfirmApiProvider),
  new Branch('stat', statApiProvider),
  new Branch('docuSign', docuSignApiProvider),
  new Branch('paymentSchedule', paymentScheduleApiProvider),
  new Branch('repaymentDetails', repaymentDetailsApiProvider)
];

const updateBalance = function*(): any {
  const userId = yield getCurrentUserId();

  if (userId) {
    yield put({
      type: getStartType('user', 'balance', actionsTypes.get),
      payload: userId
    });
  }
};

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationLoanApp = buildCommunication<ILoanAppConnectedProps>(strategy);
