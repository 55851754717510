import { AnyAction, Reducer } from 'redux';
import { RouterState } from 'react-router-redux';
import { communicationAuth, IAuthStoreProps } from 'entities/Auth/Auth.communication';
import { communicationUser, IUserStoreProps } from 'entities/User/User.communication';
import { communicationLoanApp, ILoanAppStoreProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationOnboarding } from 'entities/Onboarding/OnboardingSteps.communication';
import { communicationTransactions } from 'entities/Transactions/Transactions.communication';
import { communicationLoanAppBookmarks } from 'entities/Bookmarks/Bookmarks.communication';
import { communicationLoanAppAudit } from 'entities/LoanAppAudit/LoanAppAudit.communication';
import { communicationPayments } from 'entities/Wallet/Wallet.communication';
import { communicationDashboard } from 'entities/Dashboard/Dashboard.communications';
import { communicationKyc } from 'entities/KYCProvider/KYCProvider.communication';
import { communicationCurrency } from 'entities/Currency/Currency.communication';
import { communicationCompanies } from 'entities/Companies/Companies.communication';
import { communicationLoanDocuments } from 'entities/LoanDocuments/LoanDocuments.communication';
import { communicationUpswot } from 'entities/Upswot/Upswot.communication';
import { communicationKlarna } from 'entities/Klarna/Klarna.communication';
import { communicationOpenBanking } from 'entities/OpenBanking/OpenBanking.communication';
import { communicationBLink } from 'entities/BLink/BLink.communication';

export type RoutingReducer = Reducer<RouterState, AnyAction>;

export interface IApplicationState {
  routing?: RoutingReducer | null;
  auth: IAuthStoreProps;
  user: IUserStoreProps;
  loanApp: ILoanAppStoreProps;
  [key: string]: any;
}

const reducers = {
  ...communicationAuth.reducers,
  ...communicationUser.reducers,
  ...communicationLoanApp.reducers,
  ...communicationOnboarding.reducers,
  ...communicationTransactions.reducers,
  ...communicationLoanAppBookmarks.reducers,
  ...communicationLoanAppAudit.reducers,
  ...communicationPayments.reducers,
  ...communicationDashboard.reducers,
  ...communicationCurrency.reducers,
  ...communicationCompanies.reducers,
  ...communicationLoanDocuments.reducers,
  ...communicationUpswot.reducers,
  ...communicationKyc.reducers,
  ...communicationKlarna.reducers,
  ...communicationOpenBanking.reducers,
  ...communicationBLink.reducers
};

export default reducers;
