import React from 'react';
import i18n from 'i18n';
import { Col, message, Row, Spin, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { checkFileSize, cutFileName } from 'common/helpers/fileLoader.helper';
import { allDocsExts } from 'common/consts';
import { customUploadRequest } from 'common/helpers/upload.helper';
import UploadIcon from 'app/assets/icons/UploadIcon.svg';
import { EDocTypes, EFileStatus, EKycSteps } from 'entities/KYCProvider/KYC.const';
import { IUploadFileModel } from 'entities/KYCProvider/KYCProvider.models';
import { communicationKyc, IKycConnectedProps } from 'entities/KYCProvider/KYCProvider.communication';

interface IAntdFormControl {
  onChange?: (value?: IUploadFileModel[]) => void;
  value?: any;
}

interface IComponentProps {
  setLoading?: (value: boolean) => void;
  step: EKycSteps;
  initialValue?: IUploadFileModel[];
  disabled: boolean;
}

interface IComponentState {
  uploading: boolean;
  files: IUploadFileModel[];
}

type AllProps = IComponentProps & IAntdFormControl & IKycConnectedProps;

class MultipleFileUploaderComponent extends React.Component<AllProps, IComponentState> {
  state = { uploading: false, files: [] as IUploadFileModel[] };

  componentDidMount() {
    const { initialValue } = this.props;

    if (initialValue) {
      this.setState({ files: initialValue });
    }
  }

  render() {
    const { step, disabled } = this.props;
    const { files, uploading } = this.state;

    return (
      <Row align="top" gutter={24}>
        <Col md={12} xs={24}>
          <Upload
            type="drag"
            name="file"
            multiple={true}
            customRequest={customUploadRequest}
            accept={allDocsExts.exts}
            showUploadList={false}
            onChange={this.handleChange}
            // @ts-ignore
            beforeUpload={checkFileSize}
            disabled={uploading || disabled}
            className="form-upload"
          >
            <Row align="middle" justify="center">
              {uploading ? (
                <Spin size="small" />
              ) : (
                <>
                  <img src={UploadIcon} alt="Upload icon" className="mr-4" />
                  <span id={step === EKycSteps.LivenessCheck ? EDocTypes.UBO : step}>
                    {i18n.t<string>(`KYCStepper.uploaderTooltip.${step}`)}
                  </span>
                </>
              )}
            </Row>
          </Upload>
        </Col>
        <Col md={12} xs={24}>
          {!!files.length && (
            <>
              <Row align="top" justify="space-between">
                <span className="loaded-items__title">{i18n.t<string>('KYCStepper.uploadedFiles')}</span>
                {!disabled && (
                  <span onClick={this.onRemoveAll} className="loaded-items__remove-btn">
                    {i18n.t<string>('KYCStepper.removeAll')}
                  </span>
                )}
              </Row>
              {files.map(item => (
                <Row className="loaded-items__item" key={item.id}>
                  {!disabled && (
                    <span onClick={() => this.onRemove(item)} className="loaded-items__item_mark">
                      ✕
                    </span>
                  )}
                  <span className="loaded-items__item_name">{cutFileName(item.name)}</span>
                </Row>
              ))}
            </>
          )}
        </Col>
      </Row>
    );
  }

  handleChange = (info: UploadChangeParam<UploadFile<{ id: string }>>) => {
    const { files } = this.state;
    const { onChange, setLoading } = this.props;
    const { file, fileList } = info;
    const { status, response } = file;
    const id = response?.id;

    if (fileList.some(item => item.status === EFileStatus.Uploading)) {
      this.setState({ uploading: true });
      if (setLoading) {
        setLoading(true);
      }
    }

    if (file.status === EFileStatus.Error) {
      this.setState({
        uploading: false
      });
      if (setLoading) {
        setLoading(false);
      }
      message.error(i18n.t<string>('errors:anyUiError'));
    }

    if (status === EFileStatus.Done && id) {
      this.setState({ files: [...files, { name: file.name, id }] });

      if (onChange) {
        onChange([...files, { name: file.name, id }]);
      }
    }

    if (!fileList.some(item => item.status === EFileStatus.Uploading)) {
      this.setState({ uploading: false });
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  onRemove = (file: IUploadFileModel) => {
    const { files } = this.state;
    const { onChange } = this.props;
    const index = files.indexOf(file);
    const newFiles = files.slice();

    newFiles.splice(index, 1);
    this.setState({ files: newFiles });

    if (onChange) {
      onChange(newFiles);
    }
  };

  onRemoveAll = () => {
    const { onChange } = this.props;

    this.setState({ files: [] as IUploadFileModel[] });

    if (onChange) {
      onChange([]);
    }
  };
}

export const MultipleFileUploader = communicationKyc.injector(MultipleFileUploaderComponent);
