import React from 'react';
import i18n from 'i18n';
import { Button } from 'antd';
import Success from 'app/assets/images/Success.png';
import Failed from 'app/assets/images/Failed.png';
import { EOpenBankingStatus } from 'entities/Onboarding/OnboardingSteps.const';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';

interface IComponentProps {
  status: EOpenBankingStatus | null;
  message: string | null;
  onSkip: () => void;
  onSuccess: () => void;
  onLinkBankingAccount: () => void;
}

type AllProps = IOnboardingConnectedProps & IComponentProps;

const BankingInformationStatusComponent: React.FC<AllProps> = props => {
  const { status, message, onSkip, onSuccess, onLinkBankingAccount } = props;

  return (
    <>
      <div className="banking-information__status">
        {status === EOpenBankingStatus.Success && (
          <>
            <img className="banking-information__status-success" src={Success} alt="success" />
            <p>{i18n.t<string>('bankAccountStep.success')}</p>
          </>
        )}

        {status === EOpenBankingStatus.Failed && (
          <>
            <img src={Failed} alt="failed" />
            <p>{i18n.t<string>('bankAccountStep.failed')}</p>
            {message && <p>{message}</p>}
          </>
        )}
      </div>

      <div className="banking-information__footer">
        {status === EOpenBankingStatus.Success && (
          <Button className="form-submit-button" type="primary" onClick={onSuccess}>
            {i18n.t<string>('bankAccountStep.finish')}
          </Button>
        )}

        {status === EOpenBankingStatus.Failed && (
          <>
            <Button className="form-submit-button" type="primary" onClick={onLinkBankingAccount}>
              {i18n.t<string>('bankAccountStep.retry')}
            </Button>

            <Button className="form-secondary-button" onClick={onSkip}>
              {i18n.t<string>('bankAccountStep.later')}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export const BankingInformationStatus = communicationOnboarding.injector(BankingInformationStatusComponent);
