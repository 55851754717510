import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { EBorrowerRoutes } from 'common/models/routes.model';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { ForbidModal } from 'common/components/ForbidModal';
import { BorrowerLoansActive } from 'entities/LoanApp/components/BorrowerLoansActive';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { ILoanAppCollectionData } from 'entities/LoanApp/LoanApp.models';
import { BorrowerLoansApplications } from 'entities/LoanApp/components/BorrowerLoansApplications';
import { activeLoansStatuses, ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserStatus } from 'entities/User/User.models';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { AddKYCCard } from 'entities/LoanApp/components/AddKYCCard';
import { AddBankAccountCard } from 'entities/LoanApp/components/AddBankAccountCard';
import { TransparencyScore } from 'entities/Upswot/components/TransparencyScore';
import { paymentSchedulesTransport } from 'entities/PaymentSchedules/PaymentSchedules.transport';
import { BorrowerLoansDefaulted } from 'entities/LoanApp/components/BorrowerLoansDefaulted';

type AllProps = ILoanAppConnectedProps & IUsersConnectedProps & IOnboardingConnectedProps;

const BorrowerLoansComponent: React.FC<AllProps> = props => {
  const { loanAppCollection, userCurrent, onboardingCompanyCollection, onboardingModel, getOnboardingModel } = props;
  const { status, id: userId } = userCurrent.data || {};
  const bankAccountRequired = onboardingModel.data?.ob_step === 'skipped' || onboardingModel.data?.ob_step === 'new';
  const forbidReasons = onboardingCompanyCollection.data?.data[0]?.loanRequirements;
  const canCreateLoan = forbidReasons?.isCompanyAgeEligible && forbidReasons?.isIndustryEligible;
  const [visibleForbidCreateModal, setForbidCreateModal] = useState<boolean>(false);
  const [latePayment, setLatePayment] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (userId) {
      paymentSchedulesTransport.getPaymentScheduleLateInfo(userId).then(result => {
        setLatePayment(result?.hasLatePayments || false);
      });

      getOnboardingModel(userId);
    }
  }, [userId]);

  const { t } = useTranslation();
  const { push } = useHistory();

  useEffect(() => {
    const { getLoanAppCollection, getOnboardingCompanyCollection } = props;

    getLoanAppCollection({});
    getOnboardingCompanyCollection();
  }, []);

  const activeLoans = useMemo<ILoanAppCollectionData[] | undefined>(() => {
    return loanAppCollection.data?.data.filter(item => activeLoansStatuses.includes(item.status as ELoanAppStatus));
  }, [loanAppCollection.data]);

  const nonActiveLoans = useMemo<ILoanAppCollectionData[] | undefined>(() => {
    return loanAppCollection.data?.data.filter(
      item => !activeLoansStatuses.includes(item.status as ELoanAppStatus) && item.status !== ELoanAppStatus.Defaulted
    );
  }, [loanAppCollection.data]);

  const defaultedLoans = useMemo<ILoanAppCollectionData[] | undefined>(() => {
    return loanAppCollection.data?.data.filter(item => item.status === ELoanAppStatus.Defaulted);
  }, [loanAppCollection.data]);

  if (!loanAppCollection.data && loanAppCollection.loading) {
    return <LoadingSpinner />;
  }

  if (!onboardingCompanyCollection.data || !onboardingModel.data) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="application-info application-info_with-bg">
        <div className="d-grid d-grid_spaced-lg">
          {userId && <TransparencyScore userId={userId} canCreateLoan={canCreateLoan} forbidReasons={forbidReasons} />}

          <div className="d-grid d-grid_spaced-sm">
            {status === EUserStatus.Unconfirmed && <AddKYCCard />}

            {bankAccountRequired && <AddBankAccountCard />}
          </div>

          {!!nonActiveLoans?.length && <BorrowerLoansApplications loans={nonActiveLoans} />}

          {!!activeLoans?.length && <BorrowerLoansActive loans={activeLoans} />}

          {!!defaultedLoans?.length && <BorrowerLoansDefaulted loans={defaultedLoans} />}
        </div>

        <div className="mt-11">
          <Button
            className="form-submit-button"
            type="primary"
            loading={latePayment === undefined}
            disabled={status === EUserStatus.Unconfirmed || !canCreateLoan || !!defaultedLoans?.length}
            onClick={() => {
              latePayment ? setForbidCreateModal(true) : push(EBorrowerRoutes.CreateLoan);
            }}
          >
            {t('borrowerLoans.createLoan')}
          </Button>
        </div>
      </div>

      <ForbidModal
        header="loansCreate.needPayModal.header"
        desc="loansCreate.needPayModal.desc"
        visible={visibleForbidCreateModal}
        onCancel={() => {
          setForbidCreateModal(false);
        }}
      />
    </>
  );
};

export const BorrowerLoans = communicationOnboarding.injector(
  communicationUser.injector(communicationLoanApp.injector(BorrowerLoansComponent))
);
