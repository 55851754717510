import React from 'react';
import i18n from 'i18n';
import moment from 'moment';
import numeral from 'numeral';
import { Row } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { EDateTimeFormats, ENumberFormats } from 'common/consts';
import { RiskClass } from 'common/components/RiskClass';
import { ICreditScores, ILoanAppModel, ILoanListTabItem } from 'entities/LoanApp/LoanApp.models';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

export enum EInvestConfirmParams {
  Status = 'status',
  Reference = 'response_wkToken'
}

export enum EInvestPaymentStatus {
  Ok = 'ok',
  Error = 'error',
  Cancelled = 'cancelled'
}

export enum ELoanAppPaymentType {
  Wallet = 'wallet',
  Card = 'card'
}

export enum ELoanAppInvestStatus {
  Invested = 'invested',
  WaitingForConfirm = 'waitingForPaymentAck'
}

export enum EPaymentTypes {
  Debt = 'debt',
  Penalty = 'penalty'
}

export const OrderDirect = {
  ascend: 'ASC',
  descend: 'DESC'
};

export enum ELoanAppStatus {
  Verification = 'verification',
  AdditionalInfoRequired = 'additionalInfoRequired',
  ReadyForSign = 'readyForSign',
  LookingForInvestor = 'lookingForInvestor',
  Active = 'active',
  Completed = 'completed',
  Declined = 'declined',
  Defaulted = 'defaulted'
}

export enum ELoanListTabs {
  New = 'new',
  RateRequest = 'rateRequest',
  InfoRequested = 'infoRequested',
  Marketplace = 'marketplace',
  Bookmarks = 'bookmarks',
  Investments = 'investments',
  WaitingForSigning = 'waitingForSigning',
  Pool = 'pool',
  Funded = 'funded',
  All = 'all',
  Active = 'active',
  ClientInfo = 'clientInfo',
  Completed = 'completed',
  Declined = 'declined',
  Defaulted = 'defaulted'
}

export const specialistLoanListTabs: ILoanListTabItem[] = [
  { tab: 'tabName.new', key: ELoanListTabs.New },
  { tab: 'tabName.rateRequest', key: ELoanListTabs.RateRequest },
  { tab: 'tabName.infoRequested', key: ELoanListTabs.InfoRequested },
  { tab: 'tabName.waitingSigning', key: ELoanListTabs.WaitingForSigning },
  { tab: 'tabName.pool', key: ELoanListTabs.Pool },
  { tab: 'tabName.funded', key: ELoanListTabs.Funded },
  { tab: 'tabName.declined', key: ELoanListTabs.Declined },
  { tab: 'tabName.defaulted', key: ELoanListTabs.Defaulted },
  { tab: 'tabName.completed', key: ELoanListTabs.Completed },
  { tab: 'tabName.all', key: ELoanListTabs.All }
];

export const investorLoanListTabs: ILoanListTabItem[] = [
  { tab: 'tabName.active', key: ELoanListTabs.Active },
  { tab: 'tabName.completed', key: ELoanListTabs.Completed },
  { tab: 'tabName.defaulted', key: ELoanListTabs.Defaulted }
];

export enum ELoanAppItemStatus {
  New = 'new',
  RateRequest = 'rateRequest',
  InfoIsRequested = 'infoIsRequested',
  WaitingForSigning = 'waitingForSigning',
  Pool = 'pool',
  Funded = 'funded',
  Completed = 'completed',
  Defaulted = 'defaulted',
  Declined = 'declined'
}

const noOffering = 'no offering';

export const ownRisks: ICreditScores[] = [
  { key: 'A1', value: 'A+', probOfDefault: '0.00-0.16%', avgIntRate: '1.89-4.79%', targetDefault: '1.59-3.49%' },
  { key: 'A2', value: 'A', probOfDefault: '0.17-0.29%', avgIntRate: '3.49-5.90%', targetDefault: '2.39-4.79%' },
  { key: 'A3', value: 'A-', probOfDefault: '0.30-0.89%', avgIntRate: '4.29-7.29%', targetDefault: '3.49-6.37%' },
  { key: 'B1', value: 'B+', probOfDefault: '0.90-0.1.34%', avgIntRate: '4.54-8.54%', targetDefault: '3.97-7.42%' },
  { key: 'B2', value: 'B', probOfDefault: '1.35-2.72%', avgIntRate: '4.89-8.58%', targetDefault: '4.19-7.58%' },
  { key: 'B3', value: 'B-', probOfDefault: '2.73-3.19%', avgIntRate: '4.94-8.87%', targetDefault: '4.25-7.91%' },
  { key: 'C1', value: 'C+', probOfDefault: '3.20-3.59%', avgIntRate: '5.39-9.22%', targetDefault: '5.02-8.12%' },
  { key: 'C2', value: 'C', probOfDefault: '3.60-5.34%', avgIntRate: '5.47-9.38%', targetDefault: '5.11-8.39' },
  { key: 'C3', value: 'C-', probOfDefault: '5.35-5.98%', avgIntRate: '5.51-9.71%', targetDefault: '5.38-8.51%' },
  { key: 'D1', value: 'D+', probOfDefault: '5.99-6.60%', avgIntRate: '6.23-10.29%', targetDefault: '6.02-9.81%' },
  { key: 'D2', value: 'D', probOfDefault: '6.61-9.21%', avgIntRate: '9.21-10.81%', targetDefault: '8.89-10.25%' },
  { key: 'D3', value: 'D-', probOfDefault: '9.22-13.81%', avgIntRate: '10.36-12.24%', targetDefault: '9.06-11.02%' },
  { key: 'E1', value: 'E+', probOfDefault: '13.82-19.34%', avgIntRate: '11.39-12.39%', targetDefault: '10.67-11.02%' },
  { key: 'E2', value: 'E', probOfDefault: '19.35-24.36%', avgIntRate: '12.59-13.22%', targetDefault: '10.81-11.36%' },
  { key: 'E3', value: 'E-', probOfDefault: '24.36-28.83%', avgIntRate: '13.84-14.49%', targetDefault: '11.06-12.14%' },
  { key: 'F', value: 'F', probOfDefault: noOffering, avgIntRate: noOffering, targetDefault: noOffering }
];

export enum EScores {
  Probability = 'probability',
  Rate = 'rate',
  Target = 'target'
}

export enum EFilters {
  Company = 'companyFilter',
  Name = 'name',
  Date = 'dateFilter'
}

const tableColumnsId = 'tableColumns.id';
const tableColumnsAmount = 'tableColumns.amount';
const companyName = 'company.name';
const tableColumnsDuration = 'tableColumns.duration';

const AmountLoanComponent: React.FC<ICurrencyConnectedProps & { text: string; currency?: string }> = props => {
  const {
    currencyModel: { data: accountCurrency },
    text,
    currency
  } = props;

  return (
    <span>
      {currency || accountCurrency} <span className="medium-dark-text">{numeral(text).format(ENumberFormats.Real)}</span>
    </span>
  );
};

const AmountLoan = communicationCurrency.injector(AmountLoanComponent);

const renderAmount = (text: string, currency?: string) => <AmountLoan text={text} currency={currency} />;

const renderDuration = (text: string) => (
  <span>
    <span className="medium-dark-text">{text}</span> Months
  </span>
);

const renderUpdatedAt = (text: string) => (
  <span>
    <span className="medium-dark-text">{moment(text).format(EDateTimeFormats.FullDayShortMonthFullYear)}</span>
    <span> at {moment(text).format(EDateTimeFormats.DefaultTime)}</span>
  </span>
);

// TODO refactor this
// include all possible columns
export const loansTableColumnsFull: ColumnType<ILoanAppModel>[] = [
  {
    title: i18n.t<string>(tableColumnsId),
    dataIndex: 'name',
    sorter: true,
    key: 'id',
    render: text => <span className="medium-dark-text fs-xs">{text}</span>
  },
  {
    title: i18n.t<string>(tableColumnsAmount),
    dataIndex: 'amount',
    sorter: true,
    key: 'amount',
    render: (value, record) => {
      return renderAmount(value, record.currency);
    }
  },
  {
    title: i18n.t<string>('tableColumns.company'),
    dataIndex: companyName,
    sorter: true,
    key: companyName,
    render: (text, model) => {
      return <span className="medium-dark-text fs-xs">{model.company.name}</span>;
    }
  },
  {
    title: i18n.t<string>('tableColumns.score'),
    dataIndex: 'ownRiskClass',
    sorter: true,
    key: 'ownRiskClass',
    render: text => <RiskClass className={`risk-class--${text || '--'}`}>{text || '--'}</RiskClass>
  },
  {
    title: i18n.t<string>('tableColumns.interestRate'),
    dataIndex: 'interestRate',
    sorter: true,
    key: 'interestRate',
    render: text =>
      text && (
        <span>
          <span className="medium-dark-text">{text}</span> %
        </span>
      )
  },
  {
    title: i18n.t<string>(tableColumnsDuration),
    dataIndex: 'duration',
    sorter: true,
    key: 'duration',
    render: renderDuration
  },
  {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    title: i18n.t<string>('tableColumns.statusChanged'),
    dataIndex: 'updatedAt',
    sorter: true,
    key: 'updatedAt',
    render: renderUpdatedAt
  }
];

// without Score and Interest columns
export const loansTableColumnsShort: ColumnType<ILoanAppModel>[] = [
  {
    title: i18n.t<string>(tableColumnsId),
    dataIndex: 'name',
    sorter: true,
    render: text => <span className="medium-dark-text fs-xs">{text}</span>
  },
  {
    title: i18n.t<string>(tableColumnsAmount),
    dataIndex: 'amount',
    sorter: true,
    key: 'amount',
    render: (value, record) => {
      return renderAmount(value, record.currency);
    }
  },
  {
    title: i18n.t<string>('tableColumns.company'),
    dataIndex: companyName,
    sorter: true,
    key: 'companyId',
    render: (text, model) => {
      return <span className="medium-dark-text fs-xs">{model.company.name}</span>;
    }
  },
  {
    title: i18n.t<string>(tableColumnsDuration),
    dataIndex: 'duration',
    sorter: true,
    key: 'duration',
    render: renderDuration
  },
  {
    title: i18n.t<string>('tableColumns.statusChanged'),
    dataIndex: 'updatedAt',
    sorter: true,
    key: 'updatedAt',
    render: renderUpdatedAt
  }
];

export const loansTableColumnsInvestments: ColumnType<ILoanAppModel>[] = [
  {
    title: i18n.t<string>(tableColumnsId),
    dataIndex: 'name',
    sorter: true,
    render: text => <span className="medium-dark-text fs-xs">{text}</span>
  },
  {
    title: i18n.t<string>(tableColumnsAmount),
    dataIndex: 'loanAmount',
    key: 'amount',
    sorter: true,
    render: (value, record) => {
      return renderAmount(value, record.currency);
    }
  },
  {
    title: i18n.t<string>('tableColumns.interestRate'),
    dataIndex: 'ownRiskClass',
    key: 'interestRate',
    sorter: true,
    render: (text: string, record: ILoanAppModel) => (
      <Row>
        <span className="fs-xs mr-3">
          <span className="medium-dark-text">{record.investorInterestRate || '--'}</span> %
        </span>
        <RiskClass className={`risk-class--${text || '--'}`}>{text || '--'}</RiskClass>
      </Row>
    )
  },
  {
    title: i18n.t<string>(tableColumnsDuration),
    dataIndex: 'duration',
    sorter: true,
    render: renderDuration
  }
];
export const loansTableColumnsClientInfo: ColumnType<ILoanAppModel>[] = [
  {
    title: undefined,
    dataIndex: 'name',
    render: text => <span className="medium-dark-text fs-xs">{text}</span>
  },
  {
    title: i18n.t<string>(tableColumnsAmount),
    dataIndex: 'loanAmount',
    key: 'amount',
    sorter: function(a: ILoanAppModel, b: ILoanAppModel) {
      return a.amount > b.amount ? 1 : -1;
    },
    render: (value, record) => {
      return renderAmount(value, record.currency);
    }
  },
  {
    title: i18n.t<string>(tableColumnsDuration),
    dataIndex: 'duration',
    sorter: function(a: ILoanAppModel, b: ILoanAppModel) {
      return a.duration > b.duration ? 1 : -1;
    },
    key: 'duration',
    render: renderDuration
  },
  {
    title: i18n.t<string>('tableColumns.status'),
    dataIndex: 'status',
    key: 'status',
    render: status => i18n.t<string>(`loanProps.${status}`)
  },
  {
    title: i18n.t<string>('tableColumns.statusChanged'),
    dataIndex: 'updatedAt',
    sorter: function(a: ILoanAppModel, b: ILoanAppModel) {
      return moment(a.updatedAt).isAfter(moment(b.updatedAt)) ? 1 : -1;
    },
    key: 'updatedAt',
    render: renderUpdatedAt
  }
];

export const activeLoansStatuses: ELoanAppStatus[] = [ELoanAppStatus.Active, ELoanAppStatus.Completed];
