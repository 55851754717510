import React from 'react';
import i18n from 'i18n';
import { Button, Col, Row } from 'antd';
import { ReactComponent as UploadIcon } from 'app/assets/icons/UploadIcon.svg';
import { ReactComponent as BankIcon } from 'app/assets/icons/BankIcon.svg';

interface IComponentProps {
  disabled: boolean;
  onLinkBankingAccount: () => void;
  onManualUploadingStatements: () => void;
  onSkip: () => void;
}

export const TransactionUploadMethodScreen: React.FC<IComponentProps> = ({
  disabled,
  onLinkBankingAccount,
  onManualUploadingStatements,
  onSkip
}) => (
  <>
    <div>
      <h3 className="onboarding-stepper__block__title">{i18n.t<string>('bankAccountStep.stepDescription')}</h3>
      <Row gutter={[0, 16]}>
        <Col xs={24} lg={11}>
          <Button
            className="form-secondary-button banking-information__btn-bank"
            onClick={onLinkBankingAccount}
            disabled={disabled}
          >
            <div className="banking-information__btn-icon">
              <BankIcon />
            </div>
            {i18n.t<string>('bankAccountStep.bankCardTitle')}
          </Button>
        </Col>
        <Col xs={24} lg={2} className="d-flex d-flex_align_center d-flex_justify_center">
          <span>or</span>
        </Col>
        <Col xs={24} lg={11}>
          <Button
            className="form-secondary-button banking-information__btn-upload"
            onClick={onManualUploadingStatements}
            disabled={disabled}
          >
            <div className="banking-information__btn-icon">
              <UploadIcon />
            </div>
            {i18n.t<string>('bankAccountStep.uploadCardTitle')}
          </Button>
        </Col>
      </Row>
    </div>

    <div className="banking-information__footer">
      <Button className="form-secondary-button" onClick={onSkip} disabled={disabled}>
        {i18n.t<string>('form.skip')}
      </Button>
    </div>
  </>
);
