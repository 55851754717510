import axios, { AxiosError } from 'axios';
import { UploadRequestOption } from 'rc-upload/lib/interface';

export const customUploadRequest = ({ headers, file, onSuccess, onError }: UploadRequestOption) => {
  const formData = new FormData();
  formData.append('file', file);

  axios
    .post('files/upload', formData, { headers })
    .then(response => {
      onSuccess?.(response.data);
    })
    .catch((err: AxiosError) => {
      // @ts-ignore
      onError?.({ message: err.response?.statusText as string });
    });
};
