import {
  actionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType
} from '@axmit/redux-communications';
import { put } from '@redux-saga/core/effects';
import { openBankingTransport } from 'entities/OpenBanking/OpenBanking.transport';
import {
  IOpenBankingAccountCollection,
  IOpenBankingStatementsCollection,
  IOpenBankingStatementsParams,
  IOpenBankingStatementsQueryParams,
  IOpenBankingTransactionDetails,
  IOpenBankingTransationsQueryParams
} from 'entities/OpenBanking/OpenBanking.models';

const namespace = 'openBanking';

export interface IOpenBankingConnectedProps {
  openBankingStatements: StoreBranch<IOpenBankingStatementsCollection>;
  uploadOpenBankingStatements(params: IOpenBankingStatementsParams): void;
  getOpenBankingStatements(params: IOpenBankingStatementsQueryParams): Promise<IOpenBankingStatementsCollection>;

  openBankingAccountCollection: StoreBranch<IOpenBankingAccountCollection>;
  getOpenBankingAccountCollection(params: IOpenBankingStatementsQueryParams): void;

  openBankingTransactionDetails: StoreBranch<IOpenBankingTransactionDetails>;
  getOpenBankingTransactionDetails(params: IOpenBankingTransationsQueryParams): void;
}

const statementsApiProvider = [
  new APIProvider('upload', openBankingTransport.uploadOpenBankingStatements, {
    postSuccessHook: function*(response, payload) {
      const { userId, onSuccess } = payload;

      yield put({ type: getStartType('onboarding', 'model', actionsTypes.get), payload: userId });

      if (onSuccess) {
        onSuccess();
      }
    }
  }),
  new APIProvider(actionsTypes.get, openBankingTransport.getOpenBankingStatements)
];

const accountCollectionApiProvider = [new APIProvider(actionsTypes.get, openBankingTransport.getOpenBankingAccountCollection)];

const transactionDetailsApiProvider = [new APIProvider(actionsTypes.get, openBankingTransport.getOpenBankingTransactionDetails)];

const branches = [
  new Branch('statements', statementsApiProvider),
  new Branch('accountCollection', accountCollectionApiProvider),
  new Branch('transactionDetails', transactionDetailsApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationOpenBanking = buildCommunication<IOpenBankingConnectedProps>(strategy);
