import axios from 'axios';
import {
  IOpenBankingAccountCollection,
  IOpenBankingStatementsCollection,
  IOpenBankingStatementsParams,
  IOpenBankingStatementsQueryParams,
  IOpenBankingTransactionDetails,
  IOpenBankingTransationsQueryParams
} from 'entities/OpenBanking/OpenBanking.models';

const basePath = '/open-banking';

export const openBankingTransport = {
  uploadOpenBankingStatements: (params: IOpenBankingStatementsParams): Promise<any> =>
    axios.post(`${basePath}/statements`, params).then(r => r.data),
  getOpenBankingStatements: (params: IOpenBankingStatementsQueryParams): Promise<IOpenBankingStatementsCollection> =>
    axios.get(`${basePath}/statements`, { params }).then(r => r.data),
  getOpenBankingAccountCollection: (params: IOpenBankingStatementsQueryParams): Promise<IOpenBankingAccountCollection> =>
    axios.get(`${basePath}/accounts`, { params }).then(r => r.data),
  getOpenBankingTransactionDetails: (params: IOpenBankingTransationsQueryParams): Promise<IOpenBankingTransactionDetails> =>
    axios.get(`${basePath}/transactions`, { params }).then(r => r.data)
};
