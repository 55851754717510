import React from 'react';
import { Col, Row } from 'antd';
import i18n from 'i18n';
import { FormCard } from 'common/components/Form/FormCard';
import { BankingInformationStep } from 'entities/OpenBanking/components/BankingInformationStep';

export const BankingInformationForm: React.FC = () => {
  return (
    <div className="onboarding-stepper">
      <Row gutter={32}>
        <Col sm={{ span: 16, offset: 4 }} xs={24}>
          <FormCard title={i18n.t('bankAccountStep.header')}>
            <BankingInformationStep />
          </FormCard>
        </Col>
      </Row>
    </div>
  );
};
