import React, { useState } from 'react';
import i18n from 'i18n';
import { message, Row, Spin, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { checkFileSize, cutFileName } from 'common/helpers/fileLoader.helper';
import { allDocsExts } from 'common/consts';
import { customUploadRequest } from 'common/helpers/upload.helper';
import UploadIcon from 'app/assets/icons/UploadIcon.svg';
import { ReactComponent as TrashCanIcon } from 'app/assets/icons/TrashCanIcon.svg';
import { IUploadFileModel } from 'entities/KYCProvider/KYCProvider.models';
import { EFileStatus } from 'entities/KYCProvider/KYC.const';

interface IComponentProps {
  uploading: boolean;
  setUploading: (uploading: boolean) => void;
}

interface IAntdFormControl {
  onChange?: (value?: IUploadFileModel[]) => void;
  value?: any;
}

type AllProps = IComponentProps & IAntdFormControl;

export const ManualUploaderComponent: React.FC<AllProps> = props => {
  const { uploading, setUploading, onChange } = props;
  const [files, setFiles] = useState<IUploadFileModel[]>([]);

  const handleChange = (info: UploadChangeParam<UploadFile<{ id: string }>>) => {
    const { file, fileList } = info;
    const { status, response } = file;
    const id = response?.id;

    if (fileList.some(item => item.status === EFileStatus.Uploading)) {
      setUploading(true);
    }

    if (file.status === EFileStatus.Error) {
      setUploading(false);
      message.error(i18n.t<string>('errors:anyUiError'));
    }

    if (status === EFileStatus.Done && id) {
      setFiles([...files, { name: file.name, id }]);

      if (onChange) {
        onChange([...files, { name: file.name, id }]);
      }
    }

    if (!fileList.some(item => item.status === EFileStatus.Uploading)) {
      setUploading(false);
    }
  };

  const onRemoveAll = () => {
    setFiles([]);

    if (onChange) {
      onChange([]);
    }
  };

  const onRemove = (file: IUploadFileModel) => {
    const index = files.indexOf(file);
    const newFiles = files.slice();

    newFiles.splice(index, 1);
    setFiles(newFiles);

    if (onChange) {
      onChange(newFiles);
    }
  };

  return (
    <div className="manual-uploader">
      <Upload
        type="drag"
        name="file"
        multiple={true}
        customRequest={customUploadRequest}
        accept={allDocsExts.exts}
        showUploadList={false}
        onChange={handleChange}
        // @ts-ignore
        beforeUpload={checkFileSize}
        disabled={uploading}
        className="form-upload"
      >
        <Row align="middle" justify="center">
          {uploading ? (
            <Spin size="small" />
          ) : (
            <>
              <span>{i18n.t<string>('upload')}</span>
              <img src={UploadIcon} alt="Upload icon" className="ml-4" />
            </>
          )}
        </Row>
      </Upload>

      {!!files.length && (
        <>
          {files.map(item => (
            <Row className="loaded-items__item" key={item.id}>
              <span className="loaded-items__item_name">{cutFileName(item.name)}</span>
              <span onClick={() => onRemove(item)} className="loaded-items__item_mark ml-3">
                <TrashCanIcon />
              </span>
            </Row>
          ))}

          <Row align="top">
            <span onClick={onRemoveAll} className="loaded-items__remove-btn">
              {i18n.t<string>('KYCStepper.removeAll')}
            </span>
          </Row>
        </>
      )}
    </div>
  );
};

export const ManualUploader = ManualUploaderComponent;
