import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { EBorrowerRoutes, ECommonRoutes } from 'common/models/routes.model';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserStatus } from 'entities/User/User.models';

type AllProps = IUsersConnectedProps;

const BlinkRedirectPageComponent: React.FC<AllProps> = ({ userCurrent }) => {
  const { status } = userCurrent.data || {};
  const history = useHistory();

  useEffect(() => {
    const redirectSearchParams = history.location.search;

    if (status === EUserStatus.Onboarding) {
      history.replace(ECommonRoutes.Onboarding, redirectSearchParams);
    }

    if (status && status !== EUserStatus.Onboarding) {
      history.replace(EBorrowerRoutes.BankAccount, redirectSearchParams);
    }
  }, [status]);

  return <LoadingSpinner />;
};

export const BlinkRedirectPage = communicationUser.injector(BlinkRedirectPageComponent);
