import axios from 'axios';
import { IKlarnaSession, IKlarnaFetchParams } from 'entities/Klarna/Klarna.models';

const basePath = '/klarna';

export const klarnaTransport = {
  initKlarnaSession: (): Promise<IKlarnaSession> => axios.post(`${basePath}/account-init`).then(r => r.data),
  fetchKlarnaBankingInfo: (params: IKlarnaFetchParams): Promise<void> =>
    axios.post(`${basePath}/fetch-all`, params).then(r => r.data),
  closeKlarnaSession: (sessionId: string): Promise<void> => axios.delete(`${basePath}/session/${sessionId}`)
};
