import { Row, Button } from 'antd';
import React from 'react';
import { IOnboardingStepsModel, IOnboardingStepperItem } from 'entities/Onboarding/OnboardingSteps.models';
import { EOnboardingSteps } from 'entities/Onboarding/OnboardingSteps.const';
import { EKycStatus } from 'entities/KYCProvider/KYC.const';

interface IComponentProps {
  item: IOnboardingStepperItem<string>;
  itemStatus: string;
  currentStep?: string;
  kycStatus?: string;
  loading: boolean;
  setOnboardingStepsModel: (params: IOnboardingStepsModel) => void;
}

export const OnboardingProgressBarItem: React.FC<IComponentProps> = props => {
  const { item, itemStatus, currentStep, kycStatus, loading, setOnboardingStepsModel } = props;
  const isCurrentBtn = currentStep === item.step;

  return (
    <Row align="top" className="onboarding-progress-bar__item">
      <span className={`onboarding-progress-bar__item__point onboarding-progress-bar__item__point--${itemStatus}`} />
      {(itemStatus === 'approved' || itemStatus === 'current') && kycStatus !== EKycStatus.WaitingForApproval ? (
        <Button
          className={`onboarding-progress-bar__item_btn ${isCurrentBtn && 'onboarding-progress-bar__item_btn--current'}`}
          onClick={() => setOnboardingStepsModel({ step: item.step as EOnboardingSteps })}
          disabled={isCurrentBtn || loading}
        >
          {item.title}
        </Button>
      ) : (
        <div className={`onboarding-progress-bar__item__title onboarding-progress-bar__item__title--${itemStatus}`}>
          {item.title}
        </div>
      )}
    </Row>
  );
};
