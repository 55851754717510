import React, { useState } from 'react';
import { Form, Button } from 'antd';
import i18n from 'i18n';
import { ManualUploader } from 'entities/OpenBanking/components/ManualUploader';
import { IUploadFileModel } from 'entities/KYCProvider/KYCProvider.models';
import { communicationOpenBanking, IOpenBankingConnectedProps } from 'entities/OpenBanking/OpenBanking.communication';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';

interface IComponentProps {
  userId?: string;
  registrationCountry?: 'CH' | 'UK';
  onSkip: () => void;
  onSuccess: () => void;
  onLinkBankingAccount: () => void;
}

type AllProps = IOnboardingConnectedProps & IOpenBankingConnectedProps & IComponentProps;

const ManualUploaderScreenComponent: React.FC<AllProps> = props => {
  const { userId, registrationCountry, onSkip, onSuccess, onLinkBankingAccount, uploadOpenBankingStatements } = props;
  const [uploading, setUploading] = useState<boolean>(false);

  const onFinish = (values: { [key: string]: IUploadFileModel[] }) => {
    const { bankStatementsFiles } = values;
    const fileIds = bankStatementsFiles.map((value: IUploadFileModel) => value.id);

    userId &&
      uploadOpenBankingStatements({
        fileIds,
        userId,
        onSuccess: onSuccess
      });
  };

  return (
    <Form className="banking-information" onFinish={onFinish}>
      <div className="banking-information__manual">
        <h3 className="onboarding-stepper__block__title">{i18n.t<string>('bankAccountStep.stepDescription')}</h3>
        <Form.Item name="bankStatementsFiles" rules={[{ required: true, message: i18n.t<string>('form.fileIsRequired') }]}>
          <ManualUploader uploading={uploading} setUploading={setUploading} />
        </Form.Item>
      </div>

      <div className="banking-information__footer">
        <Button className="form-submit-button" type="primary" htmlType="submit" disabled={uploading}>
          {i18n.t<string>('continue')}
        </Button>

        <div className="banking-information__footer_container">
          <Button className="form-secondary-button" onClick={onSkip}>
            {i18n.t<string>('form.skip')}
          </Button>

          <Button className="form-submit-button" type="primary" onClick={onLinkBankingAccount}>
            {i18n.t<string>(`bankAccountStep.linkVia.${registrationCountry}`)}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export const ManualUploaderScreen = communicationOnboarding.injector(
  communicationOpenBanking.injector(ManualUploaderScreenComponent)
);
