import {
  actionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from '@redux-saga/core/effects';
import { klarnaTransport } from 'entities/Klarna/Klarna.transport';
import { IKlarnaSession, IKlarnaFetchParams } from 'entities/Klarna/Klarna.models';

const namespace = 'klarna';

export interface IKlarnaConnectedProps {
  klarnaSession: StoreBranch<IKlarnaSession>;
  initKlarnaSession(): Promise<IKlarnaSession>;
  closeKlarnaSession(sessionId: string): void;
  fetchKlarnaSession(params: IKlarnaFetchParams): void;
}

const sessionApiProvider = [
  new APIProvider(actionsTypes.init, klarnaTransport.initKlarnaSession),
  new APIProvider('colse', klarnaTransport.closeKlarnaSession),
  new APIProvider('fetch', klarnaTransport.fetchKlarnaBankingInfo, {
    postSuccessHook: function*(response: any, payload) {
      yield put({ type: getStartType('onboarding', 'model', actionsTypes.get), payload: payload.userId });
    },
    postFailHook: function*(response: any, payload) {
      yield put({ type: getStartType('klarna', 'session', 'close'), payload: payload.sessionId });
    }
  })
];

const branches = [new Branch('session', sessionApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationKlarna = buildCommunication<IKlarnaConnectedProps>(strategy);
